import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { ErrorBoundary } from "react-error-boundary";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ErrorFallback from "./components/errorHandling";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/CommonContext";
import { StripeProvider } from "react-stripe-elements";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// const {publishableKey}= await fetch('/config').then(r=>r.json())
// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
const stripeclientId: any = `${process.env.REACT_APP_STRIPE_CLIENT_KEY}`;
const stripePromise = loadStripe(stripeclientId);
const clientId: any = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const options = {
  mode: "payment",
  currency: "usd",
};
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <React.StrictMode>
      <BrowserRouter>
        {
          //@ts-ignore
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <ReduxProvider store={store}>
              <Elements stripe={stripePromise}>
                <AuthProvider>
                  <DndProvider backend={HTML5Backend}>
                    <App />
                  </DndProvider>
                </AuthProvider>
              </Elements>
            </ReduxProvider>
          </ErrorBoundary>
        }
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
