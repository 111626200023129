import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SignatureBoxDraggable from "./LeftpanelsubComponent/SignatureBoxDraggable";
import SquareBoxDraggable from "./LeftpanelsubComponent/SquareBoxDraggable";
import SquareBoxDraggableAddress from "./LeftpanelsubComponent/SquareBoxDraggableAddress";
import SquareBoxDraggableCompany from "./LeftpanelsubComponent/SquareBoxDraggableCompany";
import SquareBoxDraggableEmail from "./LeftpanelsubComponent/SquareBoxDraggableEmail";
import SquareBoxDraggableGender from "./LeftpanelsubComponent/SquareBoxDraggableGender";
import SquareBoxDraggableInitial from "./LeftpanelsubComponent/SquareBoxDraggableInitial";
import SquareBoxDraggableName from "./LeftpanelsubComponent/SquareBoxDraggableName";
import SquareBoxDraggablePhone from "./LeftpanelsubComponent/SquareBoxDraggablePhone";
import SquareBoxDraggableTitle from "./LeftpanelsubComponent/SquareBoxDraggableTitle";
import "./Leftpanel.css";
import CheckBoxDraggable from "./LeftpanelsubComponent/CheckBoxDraggable";
import RadioBoxDraggable from "./LeftpanelsubComponent/RadioBoxDraggable";
import SquareBoxDraggableDate from "./LeftpanelsubComponent/SquareBoxDraggableDate";
import InitialSignatureBoxDraggable from "./LeftpanelsubComponent/InitialSignatureBoxDraggable";

type StateType = {
  signupload: boolean;
  showpassword: boolean;
};

function LeftpanelIndex({ handleDragStart, handleTextClick }: any) {
  const [state, setState] = useState<StateType>({
    signupload: false,
    showpassword: false,
  });
  const params = useLocation();

  const nav = useNavigate();
  return (
    <div className="desc-container">
      <div className="fieldscss">
        <div className="row">
          <div className="col">
            <h5 className="ml-0">Fields</h5>
          </div>
          
        </div>
        <ul className="leftcontainer">
          <li>
            <SquareBoxDraggable
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggableName
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggableEmail
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SignatureBoxDraggable
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <InitialSignatureBoxDraggable
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggableDate
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <CheckBoxDraggable
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          {/*
            <li>
              <RadioBoxDraggable />
            </li> */}
          <li>
            <SquareBoxDraggableTitle
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggableGender
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggableAddress
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggablePhone
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
          <li>
            <SquareBoxDraggableCompany
              handleDragStart={handleDragStart}
              handleTextClick={handleTextClick}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default React.memo(LeftpanelIndex);
