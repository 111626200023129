import React, { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "../Leftpanel.css";
/**
 * Your Component
 */
function InputBox({
  left,
  top,
  index,
  onClickElement,
  selectedPageNo,
  handleChange,
  role,
  payload,
  isdate,
}: // deletefield,
any) {
  //   const [elements, setElements] = useContext(ElementContext);
  //   const [user, setUser] = useContext(UserContext);
  const parameter = useParams();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "input-box",
    item: {
      type: "input",
      left,
      top,
      index,
      position: "old",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  //   const disableInput = () => {
  //     if (user.role.toLowerCase() === role.toLowerCase()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   };
  return (
    <div>
      {/* <FontAwesomeIcon
        icon={faCircleXmark}
        className="clickable"
        style={{ ...styles.crossbtn, left, top }}
        onClick={() =>
          deletefield(index, isdate !== undefined ? "date-input" : "input")
        }
      /> */}
      <input
        ref={drag}
        type="text"
        className={`form-control drop-input`}
        onClick={(e) => {
          //   onClickElement(isdate !== undefined ? "date-input" : "input", index);
        }}
        onChange={(e) => {
          let value = e.target.value;

          value = !value.replace(/\s/g, "").length ? "" : value;
          value = value.replace(/^\s+/, "").replace(/\s+/g, " ");
          //   disableInput() === false &&
          //     isdate === undefined &&
          //     handleChange("input", index, value);
        }}
        // value={
        //   elements[isdate !== undefined ? 3 : 0].elementDetails[
        //     elements[isdate !== undefined ? 3 : 0].elementDetails?.findIndex(
        //       (pData) => pData.pageNo === selectedPageNo
        //     )
        //   ]?.elementsDesc[index - 1]?.endUserInput || ""
        // }
        // placeholder={payload}
        // disabled={disableInput()}
        style={{ left, top }}
      />
    </div>
  );
}

const styles = {
  input: {
    background: "transparent",
    zIndex: 1,
    position: "absolute",
    margin: "-3px",
    border: "1px solid black",
    height: "16px",
    width: "120px",
    padding: "5px",
  },
  crossbtn: {
    background: "transparent",
    zIndex: 1,
    position: "absolute",
    margin: "-17px",
    width: "256px",
    left: "699px",
    top: "556px",
  },
};

export default InputBox;
