import React, { useEffect, useState } from "react";
import "./dashboard-sub.css";
import { faDownLong, faFileDownload, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
import { format } from "date-fns";
import CustomDateRange from "./customDateRange";
import { data } from "jquery";
import { toast } from "react-toastify";

const WaitingForOthers = () => {
    const nav = useNavigate();
    const [totalcount, setTotalcount] = useState(0);
    const [totalpage, setTotalpage] = useState(0);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [waitingForOthersList, setWaitingForOthersList] = useState([]);
    const [filterType, setfilterType] = useState('year');
    const [customDateRange, setCustomDateRange] = useState({
        show: false,
        title: ''
    })
    useEffect(() => {
        filterData({ "filter_type": filterType },1);
    }, [])


    // download file
    const download = (file_url: any) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', file_url);
        link.setAttribute('download', `file.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    // change filter type
    const chagneFilterType = (value: any) => {
        setfilterType(value);
        if (value == 'custom') {
            setCustomDateRange({
                show: true,
                title: 'Custom Date'
            })
        } else {
            filterData({ "filter_type": value },1)
        }
    }
    const handleCustomDateRangeClose = (fromModel: any) => {

        if (fromModel.msg == 'CREATED') {
            filterData({ "filter_type": "custom", "start_date": fromModel.data.data.start_date, "end_date": fromModel.data.data.end_date },1);
        }
        setCustomDateRange({
            show: false,
            title: '',
        });
    }

    const filterData = async (params: any, pageno:number) => {
      try {
        setLoading(true);
        const url: string = `waiting-for-others?page=${pageno}`;

        const response = await axiosInstance.post(url, params);
        if (response.status == 200) {
          setWaitingForOthersList(response.data.data.waiting_for_others_list);
        }
      } catch (err: any) {
        if (err.response.data.msg) {
          toast.error(err.response.data.msg);
        } else {
          toast.error("something went wrong");
        }
      } finally {
        setLoading(false);
      }
    };
    const handleSearch = (e: any) => {

    }
    return (
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div className="row">
            <div className="col-lg-12">
              <div className="top-header-part">
                <FontAwesomeIcon
                  icon={faXmark}
                  className="ml-2 cross-icon-size clickable"
                  onClick={() => {
                    nav("/dashboard");
                  }}
                />
              </div>
            </div>
          </div>
          <div id="content" className="mt-7">
            <div className="container-fluid mb-7 mt-4">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">
                  Waiting For Others List
                </h1>
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-primary shadow-sm dropdown-toggle custom-filter-button"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {filterType}
                  </button>
                  <div className="dropdown-menu">
                    <a
                      className="dropdown-item"
                      onClick={() => chagneFilterType("week")}
                    >
                      Week
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => chagneFilterType("month")}
                    >
                      Month
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => chagneFilterType("year")}
                    >
                      Year
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => chagneFilterType("custom")}
                    >
                      Custom
                    </a>
                  </div>
                </div>
                <CustomDateRange
                  show={customDateRange.show}
                  title={customDateRange.title}
                  onClick={handleCustomDateRangeClose}
                  onHide={handleCustomDateRangeClose}
                />
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 ">
                  {loading ? (
                    <div className="spinner-div">
                      <span className="spinner custom-spinner"></span>
                    </div>
                  ) : waitingForOthersList.length !== 0 ? (
                    <div className="table-responsive">
                      <div className="">
                        {/* <div className="search-bar">
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="search-icon"
                          />
                          <input
                            className="search-box"
                            placeholder="Search"
                            id="search"
                            type="text"
                            onChange={handleSearch}
                          />
                        </div> */}
                      </div>
                      <br></br>
                      <div
                        id="tableID_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="sortTable"
                          className="table table-bordered table table-bordered da-table cusTable dataTable no-footer"
                        >
                          <thead>
                            <tr>
                              <th>Sl No</th>
                              <th>Template Name</th>
                              {/* <th>Contract Id</th> */}
                              <th style={{ textAlign: "center" }}>
                                Created Date
                              </th>
                              <th style={{ textAlign: "center" }}>
                                Modified Date
                              </th>
                              <th style={{ textAlign: "center" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {waitingForOthersList.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <div className="character-limit">
                                        {item.template_name}
                                      </div>
                                    </td>
                                    {/* <td>{item[0].id}</td> */}
                                    <td style={{ textAlign: "center" }}>
                                      {/* {format(
                                      new Date(item[0].created_at),
                                      "dd-MMM-yyyy"
                                    )} */}
                                      {item.created_at.split(" ")[0]}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {/* {format(
                                      new Date(item[0].updated_at),
                                      "dd-MMM-yyyy"
                                    )} */}
                                      {item.updated_at.split(" ")[0]}
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                      <FontAwesomeIcon
                                        icon={faFileDownload}
                                        className="download-btn"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Download File"
                                        onClick={() =>
                                          download(item.template_url)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="tableID_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing {page * 10 - 9} to{" "}
                          {totalpage !== page ? page * 10 : totalcount} of{" "}
                          {totalcount} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="tableID_paginate"
                        >
                          {page !== 1 && (
                            <a
                              className="paginate_button previous disabled clickable"
                              aria-controls="tableID"
                              id="tableID_previous"
                              onClick={() => {
                                filterData(
                                  {
                                    filter_type: filterType,
                                  },
                                  page - 1
                                );
                              }}
                            >
                              Previous
                            </a>
                          )}
                          <span>
                            {Array.from(Array(totalpage)).map((val, index) => (
                              <a
                                className={`paginate_button ${
                                  page === index + 1 ? "current" : ""
                                }`}
                                aria-controls="tableID"
                                key={index}
                                onClick={() => {
                                  filterData(
                                    {
                                      filter_type: filterType,
                                    },
                                    index + 1
                                  );
                                }}
                              >
                                {index + 1}
                              </a>
                            ))}
                          </span>
                          {page !== totalpage && (
                            <a
                              className="paginate_button next disabled clickable"
                              aria-controls="tableID"
                              id="tableID_next"
                              onClick={() => {
                                filterData(
                                  {
                                    filter_type: filterType,
                                  },
                                  page + 1
                                );
                              }}
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="angle-right"
                                className="svg-inline--fa fa-angle-right mr-1 ml-1 nav-icon-height text-right"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                                ></path>
                              </svg>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    // "No Template Found"
                    <div
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        borderTop: "2px solid #e3e6f0",
                      }}
                    >
                      {" "}
                      Currently do not have any waiting for others List.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default WaitingForOthers;