import React, { useState } from "react";
import "./sidenav.css";
import { NavLink, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChartArea,
  faTable,
  faUser,
  faTachometerAlt,
  faLaughWink,
  faUniversalAccess,
  faFile,
  faAngleRight,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import companylogo from "../../assets/images/cloud_sign_logo_white_new.png";
import accountIcn from "../../assets/images/account_icon.png";
import auditIcn from "../../assets/images/auditing_icon.png";
import contactIcn from "../../assets/images/contract_icon.png";
import dashIcn from "../../assets/images/dashboard_icon.png";
import signIcn from "../../assets/images/signing_icon.png";
import usrActivityIcn from "../../assets/images/user_activity_icon.png";
import usrIcn from "../../assets/images/user_icon.png";
import usrTypeicn from "../../assets/images/user_type_icon.png";
import { useSelector } from "../../redux/store";
function Sidenav() {
  const [showcontrol, setShowControl] = useState(false);
  const [showreport, setShowReport] = useState(false);
  const [showsetting, setShowSetting] = useState(false);
  const { userdetails } = useSelector((state: any) => state.user);
  const { pathname } = useLocation();

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          to="/dashboard"
          className="sidebar-brand d-flex align-items-center justify-content-center"
        >
          <div className="sidebar-brand-text ">
            <img
              src={companylogo}
              width="90%"
              className="mb-1"
              alt="companylogo"
            />
          </div>
        </Link>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link mr-3">
            <FontAwesomeIcon
              icon={faTachometerAlt}
              className="mr-1 nav-icon-height"
            />
            <span>Home</span>
          </NavLink>
        </li>
        {/* <hr className="sidebar-divider" />
        <div className="sidebar-heading">Interface</div> */}
        {userdetails !== null && userdetails.is_sub_user === "0" && (
          <React.Fragment>
            <li className="nav-item">
              <NavLink to="/templatelist" className="nav-link mr-3">
                <FontAwesomeIcon
                  icon={faChartArea}
                  className="mr-1 nav-icon-height"
                />
                <span>Templates</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contractlist" className="nav-link mr-3">
                <FontAwesomeIcon
                  icon={faTable}
                  className="mr-1 nav-icon-height"
                />
                <span>Contracts</span>
              </NavLink>
            </li>

            <li className="nav-item clickable">
              <a
                className="nav-link collapsed"
                onClick={() => setShowSetting(!showsetting)}
              >
                <div className="row justify-content-between m-0 p-0">
                  <div className="m-0 p-0">
                    <FontAwesomeIcon
                      icon={faUniversalAccess}
                      className="mr-1 nav-icon-height"
                    />
                    <span>Settings</span>
                  </div>
                  <div className="m-0 p-0 align-items-center">
                    {" "}
                    {showsetting ? (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="mr-1 ml-1 nav-icon-height"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mr-1 ml-1 nav-icon-height"
                      />
                    )}
                  </div>
                </div>
              </a>
              {showsetting && (
                <div
                  // id="collapseSettings"
                  className="collapse collapse-custom"
                // aria-labelledby="headingTwo"
                // data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <NavLink
                      to="/settings/account"
                      className="collapse-item mr-3"
                    >
                      <img
                        src={accountIcn}
                      
                        className="navicn"
                        alt="accountIcn"
                      />
                      <span>Account</span>
                    </NavLink>
                    <NavLink to="/settings/sign" className="collapse-item mr-3">
                    <img
                        src={signIcn}
                      
                        className="navicn"
                        alt="signIcn"
                      />
                      <span>Signing and Sending</span>
                    </NavLink>
                    <NavLink to="/settings/auditing" className="collapse-item mr-3">
                    <img
                        src={auditIcn}
                      
                        className="navicn"
                        alt="auditIcn"
                      />
                      <span>Auditing</span>
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
            <li className="nav-item clickable">
              <a
                className="nav-link collapsed"
                onClick={() => setShowControl(!showcontrol)}
              >
                <div className="row justify-content-between m-0 p-0">
                  <div className="m-0 p-0">
                    <FontAwesomeIcon
                      icon={faUniversalAccess}
                      className="mr-1 nav-icon-height"
                    />
                    <span>User Access Control</span>
                  </div>
                  <div className="m-0 p-0 align-items-center">
                    {" "}
                    {showcontrol ? (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="mr-1 ml-1 nav-icon-height"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mr-1 ml-1 nav-icon-height"
                      />
                    )}
                  </div>
                </div>
              </a>
              {showcontrol && (
                <div
                  // id="collapseSettings"
                  className="collapse collapse-custom"
                // aria-labelledby="headingTwo"
                // data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <NavLink to="/user-group" className="collapse-item mr-3">
                    <img
                        src={usrTypeicn}
                      
                        className="navicn"
                        alt="usrTypeicn"
                      />
                      <span>User Type</span>
                    </NavLink>
                    <NavLink to="/users" className="collapse-item mr-3">
                    <img
                        src={usrIcn}
                      
                        className="navicn"
                        alt="usrIcn"
                      />
                      <span>Users</span>
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
            <li className="nav-item">
              <NavLink to="/client" className="nav-link mr-3">
                <FontAwesomeIcon
                  icon={faUser}
                  className="mr-1 nav-icon-height"
                />
                <span>API Integration</span>
              </NavLink>
            </li>
            <li className="nav-item clickable">
              <a
                className="nav-link collapsed"
                onClick={() => setShowReport(!showreport)}
              >
                <div className="row justify-content-between m-0 p-0">
                  <div className="m-0 p-0">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="mr-1 nav-icon-height"
                    />
                    <span>Report</span>
                  </div>
                  <div className="m-0 p-0 align-items-center">
                    {" "}
                    {showreport ? (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="mr-1 ml-1 nav-icon-height"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="mr-1 ml-1 nav-icon-height"
                      />
                    )}
                  </div>
                </div>
                
                {/* </div> */}
              </a>
              {showreport && (
                <div
                  // id="collapseSettings"
                  className="collapse collapse-custom"
                // aria-labelledby="headingTwo"
                // data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <NavLink
                      to="/reports/dashboard"
                      className="collapse-item mr-3"
                    >
                      <img
                        src={dashIcn}
                      
                        className="navicn"
                        alt="dashIcn"
                      />
                      <span>Dashboard</span>
                    </NavLink>
                    <NavLink
                      to="/reports/contract"
                      className="collapse-item mr-3"
                    >
                      <img
                        src={contactIcn}
                      
                        className="navicn"
                        alt="contactIcn"
                      />
                      <span>Contracts</span>
                    </NavLink>
                    <NavLink to="/reports/user" className="collapse-item mr-3">
                    <img
                        src={usrActivityIcn}
                      
                        className="navicn"
                        alt="usrActivityIcn"
                      />
                      <span>User Activity</span>
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
          </React.Fragment>
        )}
        {userdetails !== null && userdetails.is_sub_user === "1" && (
          <React.Fragment>
            {userdetails.permissions.find((val: any) =>
              val.permission_key.includes("template")
            ) && (
                <li className="nav-item">
                  <NavLink to="/templatelist" className="nav-link mr-3">
                    <FontAwesomeIcon
                      icon={faChartArea}
                      className="mr-1 nav-icon-height"
                    />
                    <span>My Templates</span>
                  </NavLink>
                </li>
              )}
            {userdetails.permissions.find((val: any) =>
              val.permission_key.toLowerCase().includes("contract")
            ) && (
                <li className="nav-item">
                  <NavLink to="/contractlist" className="nav-link mr-3">
                    <FontAwesomeIcon
                      icon={faTable}
                      className="mr-1 nav-icon-height"
                    />
                    <span>Contracts</span>
                  </NavLink>
                </li>
              )}
            {userdetails.permissions.find((val: any) =>
              val.permission_key.toLowerCase().includes("client")
            ) && (
                <li className="nav-item">
                  <NavLink to="/client" className="nav-link mr-3">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="mr-1 nav-icon-height"
                    />
                    <span>API Integration</span>
                  </NavLink>
                </li>
              )}
          </React.Fragment>
        )}

        <hr className="sidebar-divider d-none d-md-block" />
        <li className="nav-item">
          <a
            href="https://docs.cloudsign.pro/cloudsign-documentation/"
            target="_blank"
            className="nav-link"
          >
            <span> User Guide</span>
          </a>
        </li>
        <li className="nav-item" style={{ marginTop: "-20px" }}>
          <a
            href="https://docs.cloudsign.pro/cloudsign-api-documentation-1/"
            target="_blank"
            className="nav-link"
          >
            <span> Developer Guide</span>
          </a>
        </li>
      </ul>
    </>
  );
}

export default Sidenav;
