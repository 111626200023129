import { createSlice } from "@reduxjs/toolkit";
// import { videodetailtype } from "global/types";
import { dispatch } from "../store";
// import axiosInstance from "utils/axios";

export type CommonStateType = {
  templatedata: templatedatatype | null;
  //   token: string;
};

type templatedatatype = {
  id: number;
  user_id: number;
  template_id: string;
  template_name: string;
  filename: string;
  stored_filename: string;
  template_url: string;
  page_no?: number;
  roles: {
    id: number;
    template_id: number;
    color_id: number;
    role: string;
    order: number;
    created_at: string;
    updated_at: string;
  }[];
  details: {};
  is_active?: string;
  is_deleted?: string;
  added_by?: number;
  deleted_by?: null;
  callback_url?: string;
  client_id?: string;
  company_bucket?: string;
  company_id?: number;
  role_id?: number;
  uq_id?: string;
  encode_checksum?:string;
};

const initialState: CommonStateType = {
  templatedata:null
};

const slice = createSlice({
  name: "userpdf",
  initialState,
  reducers: {
    settemplate(state, action) {
      state.templatedata = action.payload;
    },
    cleartemplate(state) {
      state.templatedata = null;
    }
    // ClearaddedVideoSuccess(state) {
    //   state.videoAddedflag = false;
    //   state.addedVideo = null;
    // },
  },
});

export function settemplateData(templatedata: templatedatatype) {
  dispatch(slice.actions.settemplate(templatedata));
}
export function cleartemplateData() {
  dispatch(slice.actions.cleartemplate());
}

export default slice.reducer;
