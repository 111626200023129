import { createSlice } from "@reduxjs/toolkit";
// import { videodetailtype } from "global/types";
import { dispatch } from "../store";
// import axiosInstance from "utils/axios";

export type CommonStateType = {
  elementdata: elementdatatype[];
  //   token: string;
};
type elementdatatype = {
  id: number;
  type: string;
  elementDetails: any[];
};

const initialState: CommonStateType = {
  elementdata: [
    {
      id: 1,
      type: "input",
      elementDetails: [],
    },
    {
      id: 2,
      type: "check",
      elementDetails: [],
    },
    {
      id: 3,
      type: "radio",
      elementDetails: [],
    },
    {
      id: 4,
      type: "date-input",
      elementDetails: [],
    },
    {
      id: 5,
      type: "image",
      elementDetails: [],
    },
  ],
};

const slice = createSlice({
  name: "elementpdf",
  initialState,
  reducers: {
    setelement(state, action) {
      state.elementdata = action.payload;
    },
    clearelement(state) {
      state.elementdata = [
        {
          id: 1,
          type: "input",
          elementDetails: [],
        },
        {
          id: 2,
          type: "check",
          elementDetails: [],
        },
        {
          id: 3,
          type: "radio",
          elementDetails: [],
        },
        {
          id: 4,
          type: "date-input",
          elementDetails: [],
        },
        {
          id: 5,
          type: "image",
          elementDetails: [],
        },
      ];
    }
  },
});

export function setelementData(elementdata: any) {
  dispatch(slice.actions.setelement(elementdata));
}
export function clearelementData() {
  dispatch(slice.actions.clearelement());
}

export default slice.reducer;
