import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate } from "react-router-dom";
import "./Middlepanel.css";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type StateType = {
  signupload: boolean;
  showpassword: boolean;
};


function MiddlepanelIndex(dropped:any) {
  const [numPages, setNumPages] = useState(null);
  const templatedetails = useSelector((state: any) => state.userpdf);
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <Document
      file={templatedetails.templatedata.template_url}
      //   options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
      // ref={dropped}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          scale={2.0}
          className="canvastemplate mb-1"
          // ref={dropped}
        />
      ))}
    </Document>
  );
}

export default React.memo(MiddlepanelIndex);
