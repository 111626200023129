import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./templateSetup.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../hooks/useAuth";
import imgpart1 from "../../assets/images/l-part.png";
// import imgpart2 from "../../assets/";
import { useDragDropManager, useDrop } from "react-dnd";
import imgpart3 from "../../assets/images/r-part.png";
//import Embed from "react-embed";
import Middlepanel from "./templateSetupSub/Middlepanel/Middlepanel";
import LeftpanelIndex from "./templateSetupSub/Leftpanel/Leftpanel";
import RightpanelIndex from "./templateSetupSub/Rightpanel/Rightpanel";
import { useSelector } from "react-redux";
import InputBox from "./templateSetupSub/Leftpanel/LeftpanelsubComponent/Inputbox";
import { setelementData } from "../../redux/slices/elementReducer";
import { Document, Page, pdfjs } from "react-pdf";
import "./templateSetupSub/Middlepanel/Middlepanel.css";
import Iframe from "react-iframe";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import axiosInstance from "../../utils/axiosInstance";
import AWS from "../../config/AWS";
import { v4 } from "uuid";
import { format } from "date-fns";
import { usePrevious } from "../../hooks/usePrevious";
import { settemplateData } from "../../redux/slices/pdfReducer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
let pspdfkitInstance;
let load = true;
let id = 0;
let formarr = [];
function TemplateSetupIndex() {
  const [fieldState, setFieldState] = useState(null);
  const [dragview, setDragView] = useState(true);
  const templatedetails = useSelector((state) => state.userpdf);
  const contractpdf = useSelector((state) => state.contractpdf);
  const [uploadloader, setUploadloader] = useState(false);
  const [currentfield, setCurrentfield] = useState("");
  const [rolesarray, setrolesarray] = useState([]);
  const [nextstate, setnextState] = useState({
    currentid: "",
    nextarray: [],
    nextid: "",
  });
  const [onlysignerstat, setOnlysigner] = useState(false);
  const nav = useNavigate();
  const params = useParams();
  const loc = useLocation();
  const { login } = useAuth();
  const prevfield = usePrevious({ currentfield });
  const containerRef = useRef(null);
  const dropzoneRef = useRef(null);
  const instance = useRef(null);
  const PSPDFKit = useRef(null);
  const fontsizes = [4, 6, 8, 10, 12, 14, 18, 24, 36, 48];
  const fonts = [
    "Arial",
    "Calibri",
    "Open Sans",
    "Times New Roman",
    "Comic Sans",
  ];
  useEffect(() => {
    const container = containerRef.current;

    (async function () {
      PSPDFKit.current = await import("pspdfkit");
      const { data, status } = await axiosInstance.get(
        loc.state !== null
          ? `decode-contract-details/${loc.state}`
          : `template/${params.templateid}`
      );
      if (loc.state === null) {
        let rolearrtemp = [];
        setOnlysigner(data.data.only_signer);
        data.data.roles.map((val) => {
          rolearrtemp.push({ id: val.id, role: val.role });
        });
        setrolesarray(rolearrtemp);
      }

      loc.state !== null
        ? settemplateData({
            id: data.data.contract_json.id,
            user_id: data.data.contract_json.user_id,
            template_id: data.data.contract_json.template_id,
            template_name: data.data.contract_json.template_name,
            filename: data.data.contract_json.filename,
            stored_filename: data.data.contract_json.stored_filename,
            template_url: data.data.contract_json.template_url,
            roles: [...data.data.contract_json.contract_roles],
            details: { ...data.data.contract_json.details },
            callback_url: data.data.contract_json.callback_url,
            client_id: data.data.contract_json.client_id,
            company_bucket: data.data.contract_json.company_bucket,
            company_id: data.data.contract_json.company_id,
            role_id: data.data.contract_json.role_id,
            uq_id: data.data.contract_json.uq_id,
            page_no: data.data.contract_json.page_no,
            encode_checksum: loc.state,
          })
        : settemplateData(data.data);

      const toolbarItems = PSPDFKit.current.defaultToolbarItems.filter(
        (item) => {
          return /\b(zoom-mode|zoom-in|zoom-out|spacer|sidebar-thumbnails)\b/.test(
            item.type
          );
        }
      );

      const formdesignflag = params.roleid === undefined ? true : false;
      instance.current = await PSPDFKit.current.preloadWorker({
        // licenseKey: process.env.REACT_APP_PSPDFKIT_LICENSE_KEY,
        styleSheets: [
          "./pspdfkit.css", // or local CSS file
        ],
        toolbarItems:
          params.roleid === undefined ? [...toolbarItems] : [...toolbarItems],
        container,
        zoom: 2,
        document:
          loc.state !== null
            ? data.data.contract_json.template_url
            : data.data.template_url,
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
        initialViewState: new PSPDFKit.current.ViewState({
          formDesignMode: formdesignflag,
          viewportPadding: { horizontal: 0, vertical: 5 },
        }),
      });

      instance.current = await PSPDFKit.current
        .load({
          // licenseKey: process.env.REACT_APP_PSPDFKIT_LICENSE_KEY,
          styleSheets: [
            "./pspdfkit.css", // or local CSS file
          ],
          toolbarItems:
            params.roleid === undefined ? [...toolbarItems] : [...toolbarItems],
          container,
          zoom: 2,
          document:
            loc.state !== null
              ? data.data.contract_json.template_url
              : data.data.template_url,
          baseUrl: `${window.location.protocol}//${window.location.host}/`,
          initialViewState: new PSPDFKit.current.ViewState({
            formDesignMode: formdesignflag,
            viewportPadding: { horizontal: 0, vertical: 5 },
          }),
          // isEditableAnnotation: function (annotation) {
          //   // Check if the annotation is a signature
          //   console.log(annotation,"annotationedit")
          //   return !annotation.isSignature;
          // },
          customRenderers: {
            Annotation: ({ annotation }) => {
              if (formdesignflag) return null;
              if (annotation.toJS().customData === null) return;
              if (annotation.toJS().customData.type !== "input") return null;
              if (annotation.toJS().customData.tooltip === "Date") return null;
              const node = document.createElement("div");
              node.classList.add("tooltippspdfkit");
              node.style.cssText += `height: 100%;
                                      display: block;
                                      position: relative;
                                      opacity: 0.6;
                                      border-bottom: 1px dotted black;`;
              // node.setAttribute("title", annotation.toJS().id);
              node.innerHTML = `<span 
                                  class="tooltiptextpspdfkit" 
                                  title= ${annotation.toJS().id}
                                  style="visibility: hidden;
                                  width: 120px;
                                  background-color: black;
                                  color: #fff;
                                  text-align: center;
                                  padding: 2px 0;
                                  border-radius: 6px;
                                  bottom: 100%;
                                  left: 50%;
                                  margin-left: -60px;
                                  position: absolute;
                                  z-index: 1;"
                                  >${annotation.toJS().customData.tooltip}
                                  </span>`;
              return {
                node,
                append: true,
              };
            },
          },
        })
        .then(async (instance) => {
          pspdfkitInstance = instance;

          if (params.section !== "add") {
            loadpayload(
              PSPDFKit.current,
              instance,
              loc.state !== null
                ? data.data.contract_json.details.payload
                : data.data.details.payload
            );

            const stopPropagation = (event) => event.stopImmediatePropagation();

            instance.addEventListener(
              "annotationSelection.change",
              (annotation) => {
                if (annotation.isSignature) {
                  console.log(instance.contentDocument, "checking");
                  instance.contentDocument.addEventListener(
                    "pointermove",
                    stopPropagation,
                    { capture: !0 }
                  );
                  instance.contentDocument.addEventListener(
                    "mousemove",
                    stopPropagation,
                    { capture: !0 }
                  );
                  instance.contentDocument.addEventListener(
                    "touchmove",
                    stopPropagation,
                    { capture: !0 }
                  );
                  instance.contentDocument.addEventListener(
                    "keydown",
                    stopPropagation,
                    { capture: !0 }
                  );
                } else {
                  instance.contentDocument.removeEventListener(
                    "pointermove",
                    stopPropagation,
                    { capture: !0 }
                  );
                  instance.contentDocument.removeEventListener(
                    "mousemove",
                    stopPropagation,
                    { capture: !0 }
                  );
                  instance.contentDocument.removeEventListener(
                    "touchmove",
                    stopPropagation,
                    { capture: !0 }
                  );
                  instance.contentDocument.removeEventListener(
                    "keydown",
                    stopPropagation,
                    { capture: !0 }
                  );
                }
              }
            );
          }

          const { formDesignMode } = instance.viewState;
          console.log(formDesignMode, "formDesignMode");
          // This is to avoid triggering the events when the design mode is active
          if (formDesignMode) return;

          // instance.addEventListener("annotations.update", (annotation) => {
          //     console.log(annotation, "checkanno");
          //   });

          instance.addEventListener("annotations.focus", async (event) => {
            const { annotation } = event;

            if (
              !(
                annotation instanceof
                PSPDFKit.current.Annotations.WidgetAnnotation
              )
            )
              return;
            if (annotation.toJS().customData === null) return;
            if (annotation.toJS().customData.type !== "input") return;

            const tooltip = instance.contentDocument.querySelector(
              `[title='${annotation.id}']`
            );
            if (tooltip) tooltip.style.visibility = "visible";
          });

          instance.addEventListener("annotations.blur", (event) => {
            const { annotation } = event;
            if (
              !(
                annotation instanceof
                PSPDFKit.current.Annotations.WidgetAnnotation
              )
            )
              return;
            if (annotation.toJS().customData === null) return;
            if (annotation.toJS().customData.type !== "input") return;
            const tooltip = instance.contentDocument.querySelector(
              `[title='${annotation.id}']`
            );
            if (tooltip) tooltip.style.visibility = "hidden";
          });

          // instance.addEventListener("annotations.create", async (event) => {
          //   const allannotations = await getallannotation();
          //   console.log(
          //     event.toJS(),
          //     event.toJS()[0].id,
          //     allannotations,
          //     "check"
          //   );
          //   //         allannotations.forEach(async annotation => {
          //   await pspdfkitInstance.update({
          //     id: event.toJS()[0].id,
          //     locked: true, // Update the locked state to make annotations non-draggable
          //   });
          //   // annotation.setLocked(true); // Lock each annotation to make them non-draggable
          //   // });
          // });
        });
    })();

    return () => PSPDFKit.current && PSPDFKit.current.unload(container);
  }, []);
  useEffect(() => {
    if (currentfield !== "") {
      let index = nextstate.nextarray.findIndex((val) => {
        return val.name === currentfield;
      });
      if (index !== -1) {
        let element =
          nextstate.nextarray[index].type === "signature"
            ? pspdfkitInstance.contentDocument.querySelector(
                `div[data-annotation-id='${nextstate.nextarray[index].annotationId}']`
              )
            : pspdfkitInstance.contentDocument.querySelector(
                `input[name='${nextstate.nextarray[index].name}']`
              );

        element.focus();
        setnextState((prevState) => ({
          ...prevState,
          currentid: nextstate.nextarray[index],
          nextid:
            nextstate.nextarray.length === index + 1
              ? nextstate.nextarray[0]
              : nextstate.nextarray[index + 1],
        }));
      }
      setCurrentfield("");
    }
  }, [currentfield]);

  const loadpayload = (pspdfkit, instancepdf, payload) => {
    let customarr = [];
    const finalcustomarr = [];
    payload.map((value, idx) => {
      if (value.elementDetails.length !== 0) {
        value.elementDetails.map((val, i) => {
          val.elementsDesc.map(async (elem, index) => {
            if (
              elem.customData.type === "input" ||
              elem.customData.type === "check" ||
              elem.customData.type === "signature"
            ) {
              await addfields(elem, pspdfkit, instancepdf);
              console.log(
                params.roleid,
                elem.customData.role_id.toString(),
                "elem"
              );
              if (
                params.roleid === elem.customData.role_id.toString() &&
                elem.customData.tooltip !== "Date"
              ) {
                console.log(elem, "elem");
                customarr.push({
                  name: elem.formFieldName,
                  pageIndex: elem.pageIndex,
                  type: elem.customData.type,
                  boundingBox: elem.boundingBox,
                  annotationId: elem.id,
                });
              }
            }
            id = id + 1;
          });
        });
      }
    });
    formarr = [...sortfunction(customarr)];
    setnextState((prevState) => ({
      ...prevState,
      nextarray: [...sortfunction(customarr)],
    }));
  };

  const sortfunction = (arr) => {
    let temparr = [...arr];
    temparr.sort((a, b) => {
      if (a.pageIndex < b.pageIndex) {
        return -1;
      }
      if (a.pageIndex > b.pageIndex) {
        return 1;
      }
      return 0;
    });
    temparr.sort((a, b) => {
      if (
        a.pageIndex === b.pageIndex &&
        a.boundingBox.top < b.boundingBox.top
      ) {
        return -1;
      }
      if (
        a.pageIndex === b.pageIndex &&
        a.boundingBox.top > b.boundingBox.top
      ) {
        return 1;
      }
      return 0;
    });

    return temparr;
  };

  const addfields = async (elem, pspdfkit, instancepdf) => {
    const widget = new pspdfkit.Annotations.WidgetAnnotation({
      id: elem.id,
      pageIndex: elem.pageIndex,
      formFieldName: elem.formFieldName,
      boundingBox: new pspdfkit.Geometry.Rect({
        ...elem.boundingBox,
      }),
      fontSize: elem.fontSize,
      isBold: elem.isBold,
      font: elem.font,
      customData: {
        ...elem.customData,
      },
      noView:
        params.roleid === undefined
          ? false
          : params.roleid === elem.customData.role_id.toString()
          ? false
          : true,
    });

    if (elem.customData.type === "input") {
      const form = new pspdfkit.FormFields.TextFormField({
        name: elem.formFieldName,
        annotationIds: new pspdfkit.Immutable.List([widget.id]),
        defaultValues: elem.label.includes("Date")
          ? ""
          : elem.defaultValues !== ""
          ? elem.defaultValues
          : elem.value,
        value:
          params.roleid !== elem.customData.role_id.toString()
            ? elem.value
            : elem.label.includes("Date")
            ? format(new Date(), "MM/dd/yyyy")
            : "",
        label: elem.label,
        isEditable: true,

        isFillable: true,
        isDeletable: false,
        readOnly:
          params.roleid !== elem.customData.role_id.toString()
            ? false
            : elem.label.includes("Date")
            ? true
            : false,
        required: elem.required,
      });
      await instancepdf.create([widget, form]);
      await instancepdf.addEventListener(
        "annotations.focus",
        function (annotationFocusEvent) {
          // Will log the properties of the widget annotation that was just focused.
          // pspdfkitInstance.update(formField.set(section, value));
          changefocus(
            annotationFocusEvent.annotation.toJS(),
            annotationFocusEvent.annotation.toJS().formFieldName
          );
        }
      );
    } else if (elem.customData.type === "check") {
      const form = new PSPDFKit.current.FormFields.CheckBoxFormField({
        name: elem.formFieldName,
        annotationIds: new pspdfkit.Immutable.List([widget.id]),
        options: new PSPDFKit.current.Immutable.List([
          new PSPDFKit.current.FormOption({
            label: "On",
            value: "On",
          }),
        ]),
        // defaultValues: "false",
        label: elem.label,
        isEditable: true,
        isFillable: true,
        isDeletable: true,
        required: elem.required,
      });
      await instancepdf.create([widget, form]);
      await instancepdf.addEventListener(
        "annotations.focus",
        function (annotationFocusEvent) {
          // Will log the properties of the widget annotation that was just focused.
        }
      );
      await instancepdf.addEventListener("annotations.blur", (event) => {
        changefocus(
          event.annotation.toJS(),
          event.annotation.toJS().formFieldName
        );
      });
    } else {
      const formField = new PSPDFKit.current.FormFields.SignatureFormField({
        name: elem.formFieldName,
        annotationIds: new PSPDFKit.current.Immutable.List([widget.id]),
        dragable: true,
        label: elem.label,
        isEditable: true,
        isFillable: true,
        isDeletable: true,
        required: elem.required,
      });
      await instancepdf.create([widget, formField]);
    }
  };

  const getPageVisibleRect = (pageIndex) => {
    // Page DOM element
    const pageEl = pspdfkitInstance.contentDocument.querySelector(
      `.PSPDFKit-Page[data-page-index="${pageIndex}"]`
    );
    const pageBoundingClientRect = pageEl.getBoundingClientRect();
    // Viewport DOM element
    const viewportEl =
      pspdfkitInstance.contentDocument.querySelector(".PSPDFKit-Viewport");
    // Toolbar DOM element, needs offsetting
    const toolbarEl =
      pspdfkitInstance.contentDocument.querySelector(".PSPDFKit-Toolbar");
    // Get visible page area in page units
    return pspdfkitInstance.transformContentClientToPageSpace(
      new PSPDFKit.current.Geometry.Rect({
        left: Math.max(pageBoundingClientRect.left, 0),
        top: Math.max(pageBoundingClientRect.top, toolbarEl.scrollHeight),
        width: Math.min(pageEl.clientWidth, viewportEl.clientWidth),
        height: Math.min(pageEl.clientHeight, viewportEl.clientHeight),
      }),
      pageIndex
    );
  };

  // const handleDragStart = useCallback(
  //   (ev, section, placeholder) => {
  //     console.log(ev, section, placeholder,dropzoneRef.current,"drag")
  //     if (!dropzoneRef.current) return;
  //     ev.target.classList.add("drag-over");
  //     dropzoneRef.current.classList.add("drag-over");
  //     ev.dataTransfer.setData("text/plain", ev.target);
  //     ev.dataTransfer.setData("section", section);
  //     ev.dataTransfer.setData("placeholder", placeholder);
  //   },
  //   [dropzoneRef.current]
  // );
  const handleDragStart = useCallback(
    (ev, section, placeholder) => {
      console.log(ev, section, placeholder, dropzoneRef.current, "drag");
      if (!dropzoneRef.current) return;
      // ev.target.classList.add("drag-over");
      dropzoneRef.current.classList.add("drag-over");
      ev.dataTransfer.setData("text/plain", ev.target);
      ev.dataTransfer.setData("section", section);
      ev.dataTransfer.setData("placeholder", placeholder);
    },
    [dropzoneRef.current]
  );

  const handlepage = async (ev) => {
    console.log(ev, "ev");
    let pageindex = false;
    let prevpageindex =
      pspdfkitInstance.viewState.currentPageIndex !== 0
        ? pspdfkitInstance.viewState.currentPageIndex - 1
        : null;
    let presentpageindex = pspdfkitInstance.viewState.currentPageIndex;
    let nextpageindex =
      pspdfkitInstance.viewState.currentPageIndex !==
      pspdfkitInstance.totalPageCount - 1
        ? pspdfkitInstance.viewState.currentPageIndex + 1
        : null;
    let rectprev = false;
    let rectpresent = false;
    let rectnext = false;
    let pointInPageprev = false;
    let pointInPagepresent = false;
    let pointInPagenext = false;
    if (prevpageindex !== null) {
      rectprev = new PSPDFKit.current.Geometry.Rect({
        ...getPageVisibleRect(prevpageindex).toJS(),
      });
      pointInPageprev = await pspdfkitInstance.transformClientToPageSpace(
        new PSPDFKit.current.Geometry.Point({
          x: ev.clientX,
          y: ev.clientY,
        }),
        prevpageindex
      );
      console.log(
        rectprev.isRectOverlapping(
          new PSPDFKit.current.Geometry.Rect({
            left: pointInPageprev.x,
            top: pointInPageprev.y - 10,
            width: 100,
            height: 15,
          })
        ),
        "prevstate"
      );
    }
    if (presentpageindex !== null) {
      rectpresent = new PSPDFKit.current.Geometry.Rect({
        ...getPageVisibleRect(presentpageindex).toJS(),
      });
      pointInPagepresent = await pspdfkitInstance.transformClientToPageSpace(
        new PSPDFKit.current.Geometry.Point({
          x: ev.clientX,
          y: ev.clientY,
        }),
        presentpageindex
      );
      console.log(
        rectpresent.isRectOverlapping(
          new PSPDFKit.current.Geometry.Rect({
            left: pointInPagepresent.x,
            top: pointInPagepresent.y - 10,
            width: 100,
            height: 15,
          })
        ),
        "presentstate"
      );
    }
    if (nextpageindex !== null) {
      rectnext = new PSPDFKit.current.Geometry.Rect({
        ...getPageVisibleRect(nextpageindex).toJS(),
      });
      pointInPagenext = await pspdfkitInstance.transformClientToPageSpace(
        new PSPDFKit.current.Geometry.Point({
          x: ev.clientX,
          y: ev.clientY,
        }),
        nextpageindex
      );
      console.log(
        rectnext.isRectOverlapping(
          new PSPDFKit.current.Geometry.Rect({
            left: pointInPagenext.x,
            top: pointInPagenext.y - 10,
            width: 100,
            height: 15,
          })
        ),
        "nextstate"
      );
    }

    console.log(
      pageindex,
      prevpageindex,
      presentpageindex,
      nextpageindex,
      rectprev,
      rectpresent,
      rectnext,
      "pagehandle"
    );
  };

  const handleDrop = useCallback(
    (ev) => {
      // console.log(ev,PSPDFKit.current,pspdfkitInstance,pspdfkitInstance.totalPageCount,"drop")
      if (!PSPDFKit.current || !pspdfkitInstance) return;

      (async function () {
        ev.preventDefault();
        // Get the id of the target and add the moved element to the target's DOM
        const pspdfkitpageIndex = pspdfkitInstance.viewState.currentPageIndex;

        const pointInPage = await pspdfkitInstance.transformClientToPageSpace(
          new PSPDFKit.current.Geometry.Point({
            x: ev.clientX,
            y: ev.clientY,
          }),
          pspdfkitInstance.viewState.currentPageIndex
        );
        handlepage(ev);
        const rect = new PSPDFKit.current.Geometry.Rect({
          ...getPageVisibleRect(
            pspdfkitInstance.viewState.currentPageIndex
          ).toJS(),
        });
        // const rect = getPageVisibleRect(
        //   pspdfkitInstance.viewState.currentPageIndex
        // ).toJS();
        // console.log(
        //   rect.isRectOverlapping(
        //     new PSPDFKit.current.Geometry.Rect({
        //       left: pointInPage.x,
        //       top: pointInPage.y - 10,
        //       width: 100,
        //       height: 15,
        //     })
        //   ),
        //   pspdfkitInstance.viewState.currentPageIndex,id,
        //   "check overlap"
        // );
        // if (
        //   rect.isRectOverlapping(
        //     new PSPDFKit.current.Geometry.Rect({
        //       left: pointInPage.x,
        //       top: pointInPage.y - 10,
        //       width: 100,
        //       height: 0,
        //     })
        //   )===false
        // ){
        //   return;
        // }
        id = id + 1;
        if (ev.dataTransfer.getData("section") === "input") {
          const widget = new PSPDFKit.current.Annotations.WidgetAnnotation({
            id: PSPDFKit.current.generateInstantId(),
            pageIndex: pspdfkitInstance.viewState.currentPageIndex,
            formFieldName: `MyFormField ${id}`,
            isBold: true,
            boundingBox: new PSPDFKit.current.Geometry.Rect({
              left: pointInPage.x,
              top: pointInPage.y,
              width: 100,
              height: 15,
            }),
            customData: {
              description: "name field",
              type: ev.dataTransfer.getData("section"),
              role_id: templatedetails.templatedata.roles[0].id,
              role_name: templatedetails.templatedata.roles[0].role,
              tooltip: ev.dataTransfer.getData("placeholder"),
            },
            fontSize: fontsizes[2],
            font: fonts[0],
            // additionalActions: {
            //   onFocus: new PSPDFKit.current.Actions.JavaScriptAction({
            //     script:
            //       `var f = this.getField("MyFormField ${id}").value; if(f == "` +
            //       ev.dataTransfer.getData("placeholder") +
            //       `"){this.getField("MyFormField ${id}").value="";}`,
            //   }),
            // },
          });

          const form = new PSPDFKit.current.FormFields.TextFormField({
            name: `MyFormField ${id}`,
            annotationIds: new PSPDFKit.current.Immutable.List([widget.id]),
            defaultValues: ev.dataTransfer.getData("placeholder"),
            value: ev.dataTransfer.getData("placeholder"),
            label: `${ev.dataTransfer.getData("placeholder")}_${id}`,
            isEditable: true,
            isFillable: true,
            isDeletable: true,
            required: true,
          });
          await pspdfkitInstance.create([widget, form]);
          await pspdfkitInstance.addEventListener(
            "annotations.focus",
            function (annotationFocusEvent) {
              // Will log the properties of the widget annotation that was just focused.
              changefocus(
                annotationFocusEvent.annotation.toJS(),
                annotationFocusEvent.annotation.toJS().formFieldName
              );
            }
          );
          await pspdfkitInstance.addEventListener(
            "annotations.blur",
            function (annotationFocusEvent) {
              // Will log the properties of the widget annotation that was just focused.
              changefocus(
                annotationFocusEvent.annotation.toJS(),
                annotationFocusEvent.annotation.toJS().formFieldName
              );
            }
          );
          await pspdfkitInstance.addEventListener(
            "annotations.delete",
            (deletedAnnotations) => {
              setFieldState(null);
            }
          );
          const newformFields = await pspdfkitInstance.getFormFields();

          const newformField = newformFields.find(
            (formField) => formField.name === `MyFormField ${id}`
          );
          const newannotations = await pspdfkitInstance.getAnnotations(
            pspdfkitInstance.viewState.currentPageIndex
          );
          const newwidget = newannotations.find(
            (annotation) => annotation.formFieldName === `MyFormField ${id}`
          );

          setFieldState({ ...newwidget.toJS(), ...newformField.toJS() });
        } else if (ev.dataTransfer.getData("section") === "check") {
          const widget = new PSPDFKit.current.Annotations.WidgetAnnotation({
            id: PSPDFKit.current.generateInstantId(),
            pageIndex: pspdfkitInstance.viewState.currentPageIndex,
            formFieldName: `MyFormField ${id}`,
            boundingBox: new PSPDFKit.current.Geometry.Rect({
              left: pointInPage.x,
              top: pointInPage.y,
              width: 30,
              height: 30,
            }),
            fontSize: fontsizes[2],
            font: fonts[0],
            customData: {
              description: "checkbox",
              type: ev.dataTransfer.getData("section"),
              role_id: templatedetails.templatedata.roles[0].id,
              role_name: templatedetails.templatedata.roles[0].role,
              tooltip: ev.dataTransfer.getData("placeholder"),
            },
          });

          const form = new PSPDFKit.current.FormFields.CheckBoxFormField({
            name: `MyFormField ${id}`,
            annotationIds: new PSPDFKit.current.Immutable.List([widget.id]),
            options: new PSPDFKit.current.Immutable.List([
              new PSPDFKit.current.FormOption({
                label: "On",
                value: "On",
              }),
            ]),
            // defaultValue: "false",
            label: `${ev.dataTransfer.getData("placeholder")}_${id}`,
            isEditable: true,
            isFillable: true,
            isDeletable: true,
            required: true,
          });
          await pspdfkitInstance.create([widget, form]);
          await pspdfkitInstance.addEventListener(
            "annotations.focus",
            function (annotationFocusEvent) {
              // Will log the properties of the widget annotation that was just focused.
            }
          );
          await pspdfkitInstance.addEventListener(
            "annotations.blur",
            (event) => {
              changefocus(
                event.annotation.toJS(),
                event.annotation.toJS().formFieldName
              );
            }
          );
          await pspdfkitInstance.addEventListener(
            "annotations.delete",
            (deletedAnnotations) => {
              setFieldState(null);
            }
          );
          const newformFields = await pspdfkitInstance.getFormFields();

          const newformField = newformFields.find(
            (formField) => formField.name === `MyFormField ${id}`
          );
          const newannotations = await pspdfkitInstance.getAnnotations(
            pspdfkitInstance.viewState.currentPageIndex
          );
          const newwidget = newannotations.find(
            (annotation) => annotation.formFieldName === `MyFormField ${id}`
          );
          const allannotations = await getallannotation();
          const getnewformFields = await pspdfkitInstance.getFormFields();
          console.log(getnewformFields.toJS(), allannotations, "check");

          setFieldState({ ...newwidget.toJS(), ...newformField.toJS() });
        } else if (ev.dataTransfer.getData("section") === "radio") {
          const radioWidget1 =
            new PSPDFKit.current.Annotations.WidgetAnnotation({
              id: PSPDFKit.current.generateInstantId(),
              pageIndex: pspdfkitInstance.viewState.currentPageIndex,
              formFieldName: `MyFormField ${id}`,
              boundingBox: new PSPDFKit.current.Geometry.Rect({
                left: pointInPage.x,
                top: pointInPage.y,
                width: 20,
                height: 20,
              }),
              customData: {
                description: "radiobox",
                type: ev.dataTransfer.getData("section"),
                role_id: templatedetails.templatedata.roles[0].id,
                role_name: templatedetails.templatedata.roles[0].role,
                tooltip: ev.dataTransfer.getData("placeholder"),
              },
            });
          const radioWidget2 =
            new PSPDFKit.current.Annotations.WidgetAnnotation({
              id: PSPDFKit.current.generateInstantId(),
              pageIndex: pspdfkitInstance.viewState.currentPageIndex,
              formFieldName: `MyFormField ${id}`,
              boundingBox: new PSPDFKit.current.Geometry.Rect({
                left: pointInPage.x,
                top: pointInPage.y + 30,
                width: 20,
                height: 20,
              }),
            });
          const formField =
            new PSPDFKit.current.FormFields.RadioButtonFormField({
              name: `MyFormField ${id}`,
              annotationIds: new PSPDFKit.current.Immutable.List([
                radioWidget1.id,
                radioWidget2.id,
              ]),
              options: new PSPDFKit.current.Immutable.List([
                new PSPDFKit.current.FormOption({
                  label: "Option 1",
                  value: "1",
                }),
                new PSPDFKit.current.FormOption({
                  label: "Option 2",
                  value: "2",
                }),
              ]),
              defaultValue: "1",
              label: `${ev.dataTransfer.getData("placeholder")}_${id}`,
              isEditable: true,
              isFillable: true,
              isDeletable: true,
              required: true,
            });
          pspdfkitInstance.create([radioWidget1, radioWidget2, formField]);
          await pspdfkitInstance.addEventListener(
            "annotations.focus",
            function (annotationFocusEvent) {
              // Will log the properties of the widget annotation that was just focused.
            }
          );
          await pspdfkitInstance.addEventListener(
            "annotations.blur",
            (event) => {
              changefocus(
                event.annotation.toJS(),
                event.annotation.toJS().formFieldName
              );
            }
          );
        } else {
          const widget = new PSPDFKit.current.Annotations.WidgetAnnotation({
            id: PSPDFKit.current.generateInstantId(),
            pageIndex: pspdfkitInstance.viewState.currentPageIndex,
            boundingBox: new PSPDFKit.current.Geometry.Rect({
              left: pointInPage.x,
              top: pointInPage.y,
              width: 150,
              height: 75,
            }),
            formFieldName: `my signature form field ${id}`,
            fontSize: fontsizes[2],
            font: fonts[0],
            customData: {
              description: "signature",
              type: ev.dataTransfer.getData("section"),
              role_id: templatedetails.templatedata.roles[0].id,
              role_name: templatedetails.templatedata.roles[0].role,
              tooltip: ev.dataTransfer.getData("placeholder"),
            },
          });
          const formField = new PSPDFKit.current.FormFields.SignatureFormField({
            name: `my signature form field ${id}`,
            annotationIds: new PSPDFKit.current.Immutable.List([widget.id]),
            dragable: true,
            label: `${ev.dataTransfer.getData("placeholder")}_${id}`,
            isEditable: true,
            isFillable: true,
            isDeletable: true,
            required: true,
          });
          await pspdfkitInstance.create([widget, formField]);
          await pspdfkitInstance.addEventListener(
            "annotations.focus",
            function (annotationFocusEvent) {
              // Will log the properties of the widget annotation that was just focused.
              changefocus(
                annotationFocusEvent.annotation.toJS(),
                annotationFocusEvent.annotation.toJS().formFieldName
              );
            }
          );
          // await pspdfkitInstance.addEventListener(
          //   "annotations.blur",
          //   (event) => {
          //     changefocus(
          //       event.annotation.toJS(),
          //       event.annotation.toJS().formFieldName
          //     );
          //   }
          // );
          await pspdfkitInstance.addEventListener(
            "annotations.delete",
            (deletedAnnotations) => {
              setFieldState(null);
            }
          );
          const newformFields = await pspdfkitInstance.getFormFields();

          const newformField = newformFields.find(
            (formField) => formField.name === `my signature form field ${id}`
          );
          const newannotations = await pspdfkitInstance.getAnnotations(
            pspdfkitInstance.viewState.currentPageIndex
          );
          const newwidget = newannotations.find(
            (annotation) =>
              annotation.formFieldName === `my signature form field ${id}`
          );

          setFieldState({ ...newwidget.toJS(), ...newformField.toJS() });
        }

        dropzoneRef.current.classList.remove("drag-over");
      })();
    },
    [dropzoneRef.current, pspdfkitInstance, PSPDFKit.current, templatedetails]
  );

  const changeinput = async (e, name, section, value, pageindex) => {
    const formFields = await pspdfkitInstance.getFormFields();

    const formField = formFields.find((formField) => formField.name === name);
    const annotations = await pspdfkitInstance.getAnnotations(pageindex);
    const widget = annotations.find(
      (annotation) => annotation.formFieldName === name
    );

    if (section === "description") {
      await pspdfkitInstance.update(
        widget.set("customData", { ...widget.customData, description: value })
      );
    } else if (section === "role_id") {
      let idx = templatedetails.templatedata.roles.findIndex(
        (val) => val.id.toString() === value
      );
      await pspdfkitInstance.update(
        widget.set("customData", {
          ...widget.customData,
          role_id: value,
          role_name: templatedetails.templatedata.roles[idx].role,
        })
      );
    } else if (section === "isBold") {
      // let idx = templatedetails.templatedata.roles.findIndex(
      //   (val) => val.id.toString() === value
      // );
      await pspdfkitInstance.update(widget.set("isBold", value));
    } else if (section === "font") {
      // let idx = templatedetails.templatedata.roles.findIndex(
      //   (val) => val.id.toString() === value
      // );
      await pspdfkitInstance.update(widget.set("font", value));
    } else if (section === "fontSize") {
      // let idx = templatedetails.templatedata.roles.findIndex(
      //   (val) => val.id.toString() === value
      // );
      await pspdfkitInstance.update(widget.set("fontSize", parseInt(value)));
    } else if (section === "value") {
      await pspdfkitInstance.setFormFieldValues(value);
    } else {
      await pspdfkitInstance.update(formField.set(section, value));
    }

    const newformFields = await pspdfkitInstance.getFormFields();

    const newformField = newformFields.find(
      (formField) => formField.name === name
    );
    const newannotations = await pspdfkitInstance.getAnnotations(pageindex);
    const newwidget = newannotations.find(
      (annotation) => annotation.formFieldName === name
    );

    setFieldState({ ...newwidget.toJS(), ...newformField.toJS() });
  };

  const changeview = (e) => {
    setDragView(e.target.checked);
    pspdfkitInstance.setViewState((viewState) =>
      viewState.set("formDesignMode", e.target.checked)
    );

    // if (pspdfkitInstance.viewState.formDesignMode) {
    //   pspdfkitInstance.setViewState((viewState) =>
    //     viewState.set("formDesignMode", false)
    //   );
    // } else {
    //   pspdfkitInstance.setViewState((viewState) =>
    //     viewState.set("formDesignMode", true)
    //   );
    // }
  };

  const changefocus = async (event, e) => {
    const formFields = await pspdfkitInstance.getFormFields();
    const annotations = await pspdfkitInstance.getAnnotations(
      pspdfkitInstance.viewState.currentPageIndex
    );
    const formField = formFields.find((formField) => formField.name === e);

    const widget = annotations.find(
      (annotation) => annotation.formFieldName === e
    );

    if (params.section !== "add" && params.roleid !== undefined) {
      setCurrentfield(e);
    }

    if (params.roleid === undefined) {
      setFieldState({ ...widget.toJS(), ...formField.toJS() });
    }
  };

  const handleTextClick = (event) => {
    if (!instance) {
      return;
    }

    const target = event.target;
    const pageIndex = pspdfkitInstance.viewState.currentPageIndex;
    const pageInfo = instance.pageInfoForIndex(pageIndex);

    insertTextAnnotation(
      new PSPDFKit.Geometry.Rect({
        width: 220,
        height: 217,
        left: pageInfo.width / 2 - 220 / 2,
        top: pageInfo.height / 2 - 217 / 2,
      }),
      target.innerText,
      pageIndex,
      26
    );
  };

  const insertTextAnnotation = async (pageRect, text, pageIndex, fontSize) => {
    const annotation = new PSPDFKit.Annotations.TextAnnotation({
      boundingBox: pageRect,
      text,
      pageIndex,
      fontSize,
      horizontalAlign: "center",
      verticalAlign: "center",
      backgroundColor: PSPDFKit.Color.WHITE,
    });

    await instance
      .create(annotation)
      .then((annotations) => instance.setSelectedAnnotation(annotations[0]));
  };

  const getallannotation = async (valuechange) => {
    let i = 0;
    let tempdata = [];
    do {
      let annotatontemp = await pspdfkitInstance.getAnnotations(i);
      if (valuechange) {
        tempdata.push(...annotatontemp.toJS());
      } else {
        tempdata.push(...annotatontemp.toJS());
      }
      i++;
    } while (i < templatedetails.templatedata.page_no);

    return tempdata;
  };

  const validationformpdf = (datapdf, annotationdata, formFields) => {
    let isValid = true;
    if (params.roleid !== undefined) {
      let signatureannotation = [];
      annotationdata.map((val, idx) => {
        if ("isSignature" in val) {
          signatureannotation.push(val);
        }
      });
      datapdf.map(async (val, id) => {
        if (val.type === "input") {
          if (
            params.roleid === val.role_id.toString() &&
            val.required &&
            val.value === ""
          ) {
            return (isValid = false);
          }
        } else if (val.type === "signature") {
          if (params.roleid === val.role_id.toString()) {
            const flagarr = [];
            if (signatureannotation.length !== 0) {
              signatureannotation.map((inkannotation) => {
                const rect = new PSPDFKit.current.Geometry.Rect({
                  ...inkannotation.boundingBox,
                });
                if (val.pageIndex === inkannotation.pageIndex) {
                  if (
                    rect.isRectOverlapping(
                      new PSPDFKit.current.Geometry.Rect({ ...val.boundingBox })
                    )
                  ) {
                    flagarr.push(true);
                  } else {
                    flagarr.push(false);
                  }
                }
              });
              if (flagarr.findIndex((val) => val === true) === -1) {
                return (isValid = false);
              }
            } else {
              return (isValid = false);
            }
          }
        } else if (val.type === "check") {
          if (
            params.roleid === val.role_id.toString() &&
            val.required
            
          ) {
            if(val.value.length === 0){
              return (isValid = false);
            }else{
              if(val.value[0] === "Off"){
                return (isValid = false);
              }
            }
          }
        }
      });
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    const formFields = await pspdfkitInstance.getFormFields();

    const newformFields = [];
    const labelfields = [];
    const rawpayload = [
      { id: 1, type: "input", elementDetails: [] },
      { id: 2, type: "check", elementDetails: [] },
      { id: 3, type: "radio", elementDetails: [] },
      { id: 4, type: "date-input", elementDetails: [] },
      { id: 5, type: "signature", elementDetails: [] },
    ];
    try {
      if (formFields.toJS().length !== 0) {
        const annotations = await getallannotation();

        if (params.roleid === undefined && onlysignerstat === "0") {
          const annoroleIds = annotations.map((item) =>
            parseInt(item.customData.role_id)
          );
          const rolesToCheck = rolesarray.map((item) => item.id);
          const allRolesPresent = rolesToCheck.every((role) =>
            annoroleIds.includes(role)
          );
          if (!allRolesPresent) {
            toast.error("Please select atleast one field for each role");
            return;
          }
        }
        // console.log(annotations,rolesarray,annoroleIds,rolesToCheck,allRolesPresent, "check annotations");
        formFields.toJS().map((value1, index) => {
          let idxelem = annotations.findIndex((val) => {
            return val.formFieldName === value1.name;
          });
          if(annotations[idxelem].customData.type === "check"){
            console.log(value1, "checkbox");
          }
          
          if (idxelem !== -1) {
            newformFields.push({ ...value1, ...annotations[idxelem] });
            labelfields.push({
              label: value1.label,
              boundingBox: annotations[idxelem].boundingBox,
              value:
                annotations[idxelem].customData.type === "input"
                  ? value1.value
                  : annotations[idxelem].customData.type === "check"
                  ? value1.values
                  : "",
              required: value1.required,
              pageIndex: annotations[idxelem].pageIndex,
              role_id: annotations[idxelem].customData.role_id,
              role_name: annotations[idxelem].customData.role_name,
              type: annotations[idxelem].customData.type,
            });
          }
        });
        console.log(annotations, newformFields, "check newformFields");
        let validationform = validationformpdf(
          labelfields,
          annotations,
          formFields
        );
        if (validationform) {
          setUploadloader(true);
          newformFields.map((val, i) => {
            let elementDetails;
            let pageIndex;
            if (val.customData.type === "input") {
              elementDetails = rawpayload[0].elementDetails;
              pageIndex = elementDetails.findIndex(
                (pData) => pData.pageNo === val.pageIndex + 1
              );
              if (pageIndex === -1) {
                // for new-entry
                elementDetails.push({
                  pageNo: val.pageIndex + 1,
                  elementsDesc: [
                    {
                      ...val,
                    },
                  ],
                });
              } else {
                elementDetails[pageIndex].elementsDesc.push({
                  ...val,
                });
              }
            } else if (val.customData.type === "check") {
              console.log(val, "val");
              elementDetails = rawpayload[1].elementDetails;
              pageIndex = elementDetails.findIndex(
                (pData) => pData.pageNo === val.pageIndex + 1
              );
              if (pageIndex === -1) {
                // for new-entry
                elementDetails.push({
                  pageNo: val.pageIndex + 1,
                  elementsDesc: [
                    {
                      ...val,
                      value: val.values[0],
                      // value:
                    },
                  ],
                });
              } else {
                elementDetails[pageIndex].elementsDesc.push({
                  ...val,
                  value: val.values[0],
                });
              }
            } else if (val.customData.type === "radio") {
              elementDetails = rawpayload[2].elementDetails;
              pageIndex = elementDetails.findIndex(
                (pData) => pData.pageNo === val.pageIndex + 1
              );
              if (pageIndex === -1) {
                // for new-entry
                elementDetails.push({
                  pageNo: val.pageIndex + 1,
                  elementsDesc: [
                    {
                      ...val,
                    },
                  ],
                });
              } else {
                elementDetails[pageIndex].elementsDesc.push({
                  ...val,
                });
              }
            } else if (val.customData.type === "date-input") {
              elementDetails = rawpayload[3].elementDetails;
              pageIndex = elementDetails.findIndex(
                (pData) => pData.pageNo === val.pageIndex + 1
              );
              if (pageIndex === -1) {
                // for new-entry
                elementDetails.push({
                  pageNo: val.pageIndex + 1,
                  elementsDesc: [
                    {
                      ...val,
                    },
                  ],
                });
              } else {
                elementDetails[pageIndex].elementsDesc.push({
                  ...val,
                });
              }
            } else if (val.customData.type === "signature") {
              elementDetails = rawpayload[4].elementDetails;
              pageIndex = elementDetails.findIndex(
                (pData) => pData.pageNo === val.pageIndex + 1
              );
              if (pageIndex === -1) {
                // for new-entry
                elementDetails.push({
                  pageNo: val.pageIndex + 1,
                  elementsDesc: [
                    {
                      ...val,
                    },
                  ],
                });
              } else {
                elementDetails[pageIndex].elementsDesc.push({
                  ...val,
                });
              }
            }
          });
          if (params.roleid === undefined) {
            if (templatedetails.templatedata.only_signer === "0") {
              calltemplatedetails(rawpayload);
            } else {
              const s3 = new AWS.S3();
              const uuid = `${v4()}.pdf`;
              const filenamepdf = `${localStorage.getItem(
                "company_bucket"
              )}/contract/${uuid}`;
              await pspdfkitInstance.save();
              await pspdfkitInstance
                .exportPDF({ flatten: true })
                .then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  const param = {
                    ACL: "public-read",
                    Bucket:
                      process.env.NODE_ENV === "production"
                        ? process.env.REACT_APP_BUCKET_NAME_PROD
                        : process.env.REACT_APP_BUCKET_NAME,
                    Key: filenamepdf,
                    Body: blob,
                    ContentType: "application/wasm",
                  };
                  console.log(param, "param");
                  s3.putObject(param, async (err, data) => {
                    if (err) {
                      setUploadloader(false);
                    } else {
                      callonlysigner(uuid, rawpayload, blob);
                    }
                  });
                });
            }
          } else {
            if (params.contractid === undefined) {
              const s3 = new AWS.S3();
              const uuid = `${v4()}.pdf`;
              const filenamepdf = `${localStorage.getItem(
                "company_bucket"
              )}/contract/${uuid}`;
              await pspdfkitInstance.save();
              await pspdfkitInstance
                .exportPDF({ flatten: true })
                .then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  const param = {
                    ACL: "public-read",
                    Bucket:
                      process.env.NODE_ENV === "production"
                        ? process.env.REACT_APP_BUCKET_NAME_PROD
                        : process.env.REACT_APP_BUCKET_NAME,
                    Key: filenamepdf,
                    Body: blob,
                    ContentType: "application/wasm",
                    // MIME: "application/wasm",
                  };
                  s3.putObject(param, async (err, data) => {
                    if (err) {
                      setUploadloader(false);
                    } else {
                      callsendcontract(uuid, rawpayload);
                    }
                  });
                });
            } else {
              const s3 = new AWS.S3();
              const uuid = `${v4()}.pdf`;
              const filenamepdf = `${localStorage.getItem(
                "company_bucket"
              )}/contract/${uuid}`;
              await pspdfkitInstance.save();
              await pspdfkitInstance
                .exportPDF({ flatten: true })
                .then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  const param = {
                    ACL: "public-read",
                    Bucket:
                      process.env.NODE_ENV === "production"
                        ? process.env.REACT_APP_BUCKET_NAME_PROD
                        : process.env.REACT_APP_BUCKET_NAME,
                    Key: filenamepdf,
                    Body: blob,
                    ContentType: "application/wasm",
                  };
                  s3.putObject(param, async (err, data) => {
                    if (err) {
                      setUploadloader(false);
                    } else {
                      callsavecontract(uuid, rawpayload);
                    }
                  });
                });
            }
          }
        } else {
          toast.error("Please fillup all the fields");
          setUploadloader(false);
        }
      } else {
        toast.error("Please Add Fields");
        setUploadloader(false);
      }
    } catch (err) {
      toast.error("Something went wrong!");
      setUploadloader(false);
    }
  };

  const callonlysigner = async (uuid, rawpayload, blob) => {
    try {
      const response = await axiosInstance.post(`add-only-signer-details`, {
        template_id: templatedetails.templatedata.template_id,
        upload_file: uuid,
        company_bucket: localStorage.getItem("company_bucket"),
        rawpayload,
      });

      if (response.status === 200) {
        // toast.success(response.data.msg);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        setUploadloader(false);
        nav("/contractlist");
      }
    } catch (err) {
      if (err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error("something went wrong");
      }
      setUploadloader(false);
    }
  };
  const callsavecontract = async (uuid, rawpayload) => {
    try {
      const response = await axiosInstance.post(`save-contract`, {
        template_id: templatedetails.templatedata.template_id,
        encode_checksum: templatedetails.templatedata.encode_checksum,
        contract_id: params.contractid,
        upload_file: uuid,
        role_id: params.roleid,
        company_bucket: localStorage.getItem("company_bucket"),
        rawpayload,
      });
      setUploadloader(false);
      if (response.status === 200) {
        // toast.success(response.data.msg);
        nav("/usersuccess");
      } else {
        toast.error(
          response.data.msg ? response.data.msg : "Something went wrong!"
        );
      }
    } catch (err) {
      if (err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error("something went wrong");
      }
      setUploadloader(false);
    }
  };
  const callsendcontract = async (uuid, rawpayload) => {
    try {
      const response = await axiosInstance.post(`send-contract`, {
        template_id: templatedetails.templatedata.id,
        subject: contractpdf.subject,
        message: contractpdf.message,
        is_mobile_send: contractpdf.is_mobile_send,
        upload_file: uuid,
        roledescription: [...contractpdf.role_description],
        rawpayload,
      });
      setUploadloader(false);
      if (response.status === 200) {
        toast.success(response.data.msg);
        nav("/contractlist");
      } else {
        toast.error(
          response.data.msg ? response.data.msg : "Something went wrong!"
        );
      }
    } catch (err) {
      if (err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error("something went wrong");
      }
      setUploadloader(false);
    }
  };
  const calltemplatedetails = async (rawpayload) => {
    try {
      const response =
        params.section === "add"
          ? await axiosInstance.post(`add-template-details`, {
              template_id: templatedetails.templatedata.id,
              storedFilename: templatedetails.templatedata.stored_filename,
              rawpayload,
            })
          : await axiosInstance.put(`update-template-details`, {
              template_id: templatedetails.templatedata.id,
              storedFilename: templatedetails.templatedata.stored_filename,
              rawpayload,
            });

      setUploadloader(false);
      if (response.status === 200) {
        toast.success(response.data.msg);
        nav("/templatelist");
      } else {
        toast.error(
          response.data.msg ? response.data.msg : "Something went wrong!"
        );
      }
    } catch (err) {
      if (err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error("something went wrong");
      }
      setUploadloader(false);
    }
  };

  const handlenext = async () => {
    if (nextstate.currentid === "") {
      const newState = pspdfkitInstance.viewState.set(
        "currentPageIndex",
        nextstate.nextarray[0].pageIndex
      );
      await pspdfkitInstance.setViewState(newState);

      setTimeout(() => {
        let element =
          nextstate.nextarray[0].type === "signature"
            ? pspdfkitInstance.contentDocument.querySelector(
                `div[data-annotation-id='${nextstate.nextarray[0].annotationId}']`
              )
            : pspdfkitInstance.contentDocument.querySelector(
                `input[name='${nextstate.nextarray[0].name}']`
              );
        element.focus();
        setnextState((prevState) => ({
          ...prevState,
          currentid: nextstate.nextarray[0],
          nextid:
            nextstate.nextarray.length > 1
              ? nextstate.nextarray[1]
              : nextstate.nextarray[0],
        }));
      }, 500);
    } else {
      const newState = pspdfkitInstance.viewState.set(
        "currentPageIndex",
        nextstate.nextid.pageIndex
      );
      await pspdfkitInstance.setViewState(newState);
      setTimeout(() => {
        let element =
          nextstate.nextid.type === "signature"
            ? pspdfkitInstance.contentDocument.querySelector(
                `div[data-annotation-id='${nextstate.nextid.annotationId}']`
              )
            : pspdfkitInstance.contentDocument.querySelector(
                `input[name='${nextstate.nextid.name}']`
              );

        let index = nextstate.nextarray.findIndex(
          (val) => val.name === nextstate.nextid.name
        );
        setnextState((prevState) => ({
          ...prevState,
          currentid: nextstate.nextid,
          nextid:
            index + 1 === nextstate.nextarray.length
              ? nextstate.nextarray[0]
              : nextstate.nextarray[index + 1],
        }));
        element.focus();
      }, 500);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="top-footer-part-setup text-center">
            <button
              className="btn btn-secondary mr-2"
              onClick={() => {
                nav(-1);
              }}
              disabled={uploadloader}
            >
              {!uploadloader ? (
                "Back"
              ) : (
                <div className="spinner-border spinner-border-sm"></div>
              )}
            </button>
            <button
              className="btn btn-primary  mr-2"
              onClick={handleSubmit}
              disabled={uploadloader}
            >
              {!uploadloader ? (
                "Submit"
              ) : (
                <div className="spinner-border spinner-border-sm"></div>
              )}
            </button>

            {params.section !== "add" && params.roleid !== undefined && (
              <button className="btn btn-success" onClick={handlenext}>
                {nextstate.currentid === "" ? "Start" : "Next"}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="row mt-7">
        {params.roleid === undefined &&
          loc.pathname.includes("templatesetup") && (
            <div className="col-lg-2">
              {templatedetails.templatedata !== null &&
                templatedetails.templatedata.roles.length === 1 && (
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      checked={dragview}
                      onChange={(e) => changeview(e)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch1"
                    >
                      Enable Field Dragging
                    </label>
                  </div>
                )}
              <LeftpanelIndex
                handleDragStart={handleDragStart}
                handleTextClick={handleTextClick}
              />
            </div>
          )}
        <div
          className={`col-lg-${
            params.roleid === undefined
              ? fieldState === null
                ? loc.pathname.includes("templatesetup")
                  ? 10
                  : 12
                : loc.pathname.includes("templatesetup")
                ? 8
                : 10
              : 12
          }`}
          onDrop={handleDrop}
          onDragOver={(ev) => {
            console.log(ev, "event");
            ev.preventDefault();
          }}
        >
          <div
            // onDrop={handleDrop}
            // onDragOver={(ev) => {
            //   console.log(ev,"event")
            //   ev.preventDefault();
            // }}
            ref={dropzoneRef}
          >
            <div
              ref={containerRef}
              style={{
                height: "calc(100vh - 10vh)",
                backgroundColor: "#4d525d",
              }}
            />
          </div>
        </div>

        {params.roleid === undefined && fieldState !== null && (
          <div className="col-lg-2">
            <RightpanelIndex
              fontsizes={fontsizes}
              fonts={fonts}
              fieldState={fieldState}
              changeinput={changeinput}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function closestByClass(el, className) {
  console.log(el, className, "check closet");
  return el && el.classList && el.classList.contains(className)
    ? el
    : el
    ? closestByClass(el.childNode, className)
    : null;
}
export default React.memo(TemplateSetupIndex);
