import {
  faClose,
  faExclamation,
  faExclamationCircle,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setuserDetailsData } from "../../redux/slices/userReducer";
import axiosInstance from "../../utils/axiosInstance";
import Packageselection from "./packageselection";
import "./packageselection.css";
import Paymentcheckout from "./paymentcheckout";

const PackageselectionIndex = ({ show, handlepackage }: any) => {
  const [state, setState] = useState<any>({
    packages: [],
    package_timeframe: "YEARLY",
    package_id: "",
    isLoading: true,
    isSubmitLoading: false,
    section: "package",
  });
  const {
    packages,
    package_id,
    package_timeframe,
    isLoading,
    isSubmitLoading,
    section,
  } = state;
  const { userdetails } = useSelector((state: any) => state.user);
  useEffect(() => {
    fetchpackage();
  }, []);

  const fetchpackage = async () => {
    try {
      const url: string = `packages`;
      const response = await axiosInstance.get(url);
      if (response.status == 200) {
        setState((prevState: any) => ({
          ...prevState,
          packages: [...response.data.data.packageList],
        }));
      }
    } catch (err: any) {
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleselectpackage = (id: number) => {
    setState((prevState: any) => ({
      ...prevState,
      package_id: id,
    }));
  };

  const handlesectionchange = (sectionvalue: string) => {
    setState((prevState: any) => ({
      ...prevState,
      section: sectionvalue,
    }));
  };

  const handleChange = (value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      package_timeframe: value,
    }));
  };

  const handleNext = () => {
    if(package_id!==""){
      handlesectionchange("checkout");
    }else{
      toast.error("please select a package");
    }
  };



  const submitpackage = async () => {
    try {
      setState((prevState: any) => ({
        ...prevState,
        isSubmitLoading: true,
      }));
      const url: string = `upgrade-user-package`;
      const response = await axiosInstance.post(url, {
        package_id,
        package_timeframe,
      });
      if (response.status == 200) {
        fetchData();
      }
    } catch (err: any) {
      if (err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error("something went wrong");
      }
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        isSubmitLoading: false,
      }));
    }
  };

  const fetchData = async () => {
    try {
      const url: string = `user-details`;
      const response = await axiosInstance.get(url);
      if (response.status == 200) {
        setuserDetailsData(response.data.data.user_json);
        handlepackage(false);
      }
      return response;
    } catch (err: any) {}
  };
 
  return (
    <>
      <Modal size="lg" show={show} centered>
        <Modal.Body>
          {section === "package" ? (
            <Packageselection
              handlepackage={handlepackage}
              isLoading={isLoading}
              handleChange={handleChange}
              package_timeframe={package_timeframe}
              packages={packages}
              package_id={package_id}
              handleselectpackage={handleselectpackage}
              handleNext={handleNext}
              isSubmitLoading={isSubmitLoading}
              handlesectionchange={handlesectionchange}
            />
          ) : (
            <Paymentcheckout
              handlepackage={handlepackage}
              submitpackage={submitpackage}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PackageselectionIndex;
