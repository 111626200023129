import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import HeaderIndex from "../shared/header";
import Sidenav from "../shared/sidenav";
// hooks
// import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

export default function AuthGuard({ children,background }: any) {
  //   const { isAuthenticated } = useAuth();
  const token = localStorage.getItem("access_token") ? true : false;
  if (!token) {
    return <Navigate to="/" />;
  }
  if(background){
   return <div id="wrapper">
      {/* Side nav mane */}
      <Sidenav />
      {/* side nav menu end */}

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          {/* Header memu */}
          <HeaderIndex />
          {/* Header menu end */}
          <>{children}</>
        </div>
      </div>
    </div>;
  }
  
  return <>{children}</>;
}
