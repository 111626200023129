import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faUser,
  faPowerOff,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";
import companylogo from "../../assets/images/cloud_sign_logo_white_new.png";
import profilelogo from "../../assets/images/no-profile.png";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axiosInstance";
import LogoutConfirmation from "../logoutConfirmation/logoutConfirmation";
import { setuserDetailsData } from "../../redux/slices/userReducer";
import { useSelector } from "../../redux/store";
import GooglePayButton from "@google-pay/button-react";
import Packageselection from "../packageSelection";
interface file {
  file_url: string | null;
}

function HeaderIndex() {
  const { logout } = useAuth();
  const { userdetails } = useSelector((state: any) => state.user);
  const nav = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    profile_image: "",
  });
  const [showmenu, setShowmenu] = useState(false);
  const [paymentmodal, setPaymentmodal] = useState(false);
  const [logoutConfirmationModel, setLogoutConfirmationModel] = useState({
    show: false,
    title: "",
    body: "",
  });

  // open delete client model
  const handleModelShow = () => {
    setLogoutConfirmationModel({
      show: true,
      title: "Confirmation Logout",
      body: "Are you sure you want to logout?",
    });
  };

  const handleModelClose = (fromModal: any) => {
    if (fromModal.msg == true) {
      logout();
      nav("/login");
    }

    setLogoutConfirmationModel({
      show: false,
      title: "",
      body: "",
    });
  };

  const profile_image: string | null = sessionStorage.getItem("file_url");
  let file_url = "";
  if (profile_image) {
    file_url = profile_image;
    // (
    //   <img
    //     src={profile_image}
    //   />
    // )
  }

  useEffect(() => {
    if (userdetails !== null) {
      sessionStorage.setItem(
        "companyname",
        userdetails.company.company_name != null
          ? userdetails.company.company_name
          : sessionStorage.getItem("companyname")
      );
      sessionStorage.setItem(
        "file_url",
        userdetails.file_url ? userdetails.file_url : ""
      );
    }
  }, [userdetails]);

  const handlepackage = (flag: boolean) => {
    setPaymentmodal(flag);
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      {userdetails !== null && (
        <div className="float-left row ml-3 capitalizetrial">
          {userdetails.trail_message}{" "}
          <button
            className="btn-upload-upgrade ml-2 d-block"
            onClick={() => handlepackage(true)}
          >
            Upgrade Now
          </button>
        </div>
      )}
      <ul className="navbar-nav dropdown ml-auto clickable">
        <div className="topbar-divider d-none d-sm-block"></div>
        <li
          className="nav-item dropdown no-arrow"
          onMouseEnter={() => setShowmenu(true)}
          onMouseLeave={() => setShowmenu(false)}
        >
          {/* <nav> */}
          <ul className="hov">
            <li>
              Hello {sessionStorage.getItem("fname")}
              <span>
                {sessionStorage.getItem("file_url") == "" ? (
                  <img
                    src={profilelogo}
                    className="no-profile-img ml-2"
                    alt=""
                  />
                ) : (
                  <img src={file_url} className="no-profile-img ml-2" alt="" />
                )}
              </span>
              {showmenu && (
                <ul className="main">
                  <Link to="/profile" className="Profile-link">
                    <li>
                      <FontAwesomeIcon icon={faUser} /> Profile
                    </li>
                  </Link>

                  <li onClick={handleModelShow}>
                    <FontAwesomeIcon icon={faPowerOff} /> Logout
                  </li>
                </ul>
              )}
            </li>
          </ul>
          {/* </nav> */}
        </li>
      </ul>
      {paymentmodal && (
        <Packageselection show={paymentmodal} handlepackage={handlepackage} />
      )}

      <LogoutConfirmation
        show={logoutConfirmationModel.show}
        title={logoutConfirmationModel.title}
        body={logoutConfirmationModel.body}
        onClick={handleModelClose}
        onHide={handleModelClose}
      />
    </nav>
  );
}

export default HeaderIndex;
