// import AuthGuard from "guard/authguard";
import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import WaitingForOthers from "../components/dashboard/dashboard-sub/waitingForOthers";
import AuthGuard from "../guard/authguard";
// import AuthGuard from "../guard/authguard";
// import DefaultLayout from "../layout";
// import { UploadPdf, SetUpPdf, User, Templates } from "../pages";
// import Congratulations from "../pages/CongratulationsPage/Congratulations";
// import Registration from "../pages/Registration/Registration";
// import SetUpUser from "../pages/SetUpUser/SetUpUser";
// import DefaultLayout from "../layout";
import TemplateSetupIndex from "../components/templateSetup";
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<div></div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/reports/:sections",
      element: (
        <AuthGuard background={true}>
          <ReportIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/templatesetup/:section/:templateid",
      element: (
        <AuthGuard>
          <TemplateSetupIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/templatesetup/:section/:templateid/:roleid",
      element: (
        <AuthGuard>
          <TemplateSetupIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/templatesetup/:section/:templateid/:roleid/:contractid",
      element: (
        // <AuthGuard>
        <TemplateSetupIndex />
        // </AuthGuard>
      ),
    },
    {
      path: "/templateredirect/:encrypteddata",
      element: <Redirecttopdf />,
    },
    {
      path: "/sendcontract",
      element: (
        <AuthGuard background={true}>
          <SendContract />
        </AuthGuard>
      ),
    },
    {
      path: "/sendcontract/:templateid",
      element: (
        <AuthGuard background={true}>
          <SendContract />
        </AuthGuard>
      ),
    },
    {
      path: "/contractlist",
      element: (
        <AuthGuard background={true}>
          <ContractListIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/pendingcontract/:contractid",
      element: (
        <AuthGuard background={true}>
          <PendingContract />
        </AuthGuard>
      ),
    },
    {
      path: "/templatelist",
      element: (
        <AuthGuard background={true}>
          <TemplateListIndex />
        </AuthGuard>
      ),
    },

    {
      path: "/templateDnd/:templatename",
      element: (
        <AuthGuard>
          <TemplateDndIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/templatecreation/:section",
      element: (
        <AuthGuard>
          <TemplateCreationIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard background={true}>
          <DashboardIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/dashboard/action-required",
      element: (
        <AuthGuard>
          <ActionRequired />
        </AuthGuard>
      ),
    },
    {
      path: "/dashboard/waiting-for-others",
      element: (
        <AuthGuard>
          <WaitingForOthers />
        </AuthGuard>
      ),
    },
    {
      path: "/dashboard/completed",
      element: (
        <AuthGuard>
          <CompletedSignList />
        </AuthGuard>
      ),
    },
    {
      path: "/client",
      element: (
        <AuthGuard background={true}>
          <ClientIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/profile",
      element: (
        <AuthGuard background={true}>
          <ProfileIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/users",
      element: (
        <AuthGuard background={true}>
          <UserIndex />
        </AuthGuard>
      ),
    },
    {
      path: "/user-group",
      element: (
        <AuthGuard background={true}>
          <UserGroup />
        </AuthGuard>
      ),
    },
    {
      path: "/connected-with-twilio",
      element: (
        <AuthGuard>
          <ConnectedApp />
        </AuthGuard>
      ),
    },
    {
      path: "/signatures",
      element: (
        <AuthGuard>
          <Signatures />
        </AuthGuard>
      ),
    },
    {
      path: "/settings/:section",
      element: (
        <AuthGuard background={true}>
          <Settings />
        </AuthGuard>
      ),
    },
    {
      path: "/usersuccess",
      element: <Congratulations />,
    },
    {
      path: "/register",
      element: <RegisterIndex />,
    },
    {
      path: "/signup",
      element: <SignupIndex />,
    },
    {
      path: "/login",
      element: <LoginIndex />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordIndex />,
    },
    {
      path: "/verify-otp",
      element: <VerifyOtp />,
    },
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },

    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}

const LoginIndex = Loadable(lazy(() => import("../components/login/index")));
const RegisterIndex = Loadable(lazy(() => import("../components/register")));
const ForgotPasswordIndex = Loadable(
  lazy(() => import("../components/fogotPassword"))
);
const VerifyOtp = Loadable(lazy(() => import("../components/verifyOtp")));
const DashboardIndex = Loadable(lazy(() => import("../components/dashboard")));
const ActionRequired = Loadable(lazy(() => import("../components/dashboard/dashboard-sub/actionRequired")));
const waitingForOthers = Loadable(lazy(() => import("../components/dashboard/dashboard-sub/waitingForOthers")));
const CompletedSignList = Loadable(lazy(() => import("../components/dashboard/dashboard-sub/completed")));

const TemplateCreationIndex = Loadable(
  lazy(() => import("../components/templateCreation"))
);
const TemplateDndIndex = Loadable(
  lazy(() => import("../components/templatednd"))
);
// const TemplateSetupIndex = Loadable(
//   lazy(() => import("../components/templateSetup"))
// );
const ProfileIndex = Loadable(lazy(() => import("../components/my-profile")));
const Settings = Loadable(
  lazy(() => import("../components/settings/index"))
);
const Signatures = Loadable(
  lazy(() => import("../components/settings/signatures"))
);
const ConnectedApp = Loadable(
  lazy(() => import("../components/settings/connected-app/connect-with-twilio"))
)
const TemplateListIndex = Loadable(
  lazy(() => import("../components/templateList"))
);

const ContractListIndex = Loadable(
  lazy(() => import("../components/contractlist"))
);

const SendContract = Loadable(lazy(() => import("../components/sendcontract")));
const PendingContract = Loadable(lazy(() => import("../components/pendingcontractdetails")));
const ClientIndex = Loadable(lazy(() => import("../components/client")));
//const ClientIndex = Loadable(lazy(() => import("../components/apiIntegration")));
const UserIndex = Loadable(lazy(() => import("../components/users/userindex")));
const UserGroup = Loadable(lazy(() => import("../components/userGroup/index")));
const SignupIndex = Loadable(lazy(() => import("../components/signup/index")));
const Redirecttopdf = Loadable(
  lazy(() => import("../components/redirecttopdf"))
);
const Congratulations = Loadable(
  lazy(() => import("../components/CongratulationsPage"))
);
const ReportIndex = Loadable(
  lazy(() => import("../components/report"))
);
