import React, { useMemo, useState, useEffect } from "react";
import {
  useStripe,
  PaymentRequestButtonElement,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const PaymentRequestForm = () => {
  const stripe = useStripe();
  useEffect(() => {
    // const stripe = Stripe(publishableKey, {
    //   apiVersion: "2020-08-27",
    // });

    // 2. Create a payment request object
    var paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Demo total",
        amount: 1999,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // 3. Create a PaymentRequestButton element
    const elements = stripe.elements();
    const prButton = elements.create("paymentRequestButton", {
      paymentRequest: paymentRequest,
    });

    // Check the availability of the Payment Request API,
    // then mount the PaymentRequestButton
    paymentRequest.canMakePayment().then(function (result) {
      if (result) {
        prButton.mount("#payment-request-button");
      } else {
        document.getElementById("payment-request-button").style.display =
          "none";
        toast.error(
          "Google Pay support not found. Check the pre-requisites above and ensure you are testing in a supported browser."
        );
      }
    });

    paymentRequest.on("paymentmethod", async (e) => {
      // Make a call to the server to create a new
      // payment intent and store its client_secret.
      const { error: backendError, clientSecret } = await fetch(
        "/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: "usd",
            paymentMethodType: "card",
          }),
        }
      ).then((r) => r.json());

      if (backendError) {
        toast.error(backendError.message);
        e.complete("fail");
        return;
      }

      toast.error(`Client secret returned.`);

      // Confirm the PaymentIntent without handling potential next actions (yet).
      let { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: e.paymentMethod.id,
        },
        {
          handleActions: false,
        }
      );

      if (error) {
        toast.error(error.message);

        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        e.complete("fail");
        return;
      }
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      e.complete("success");

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11" instead
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        let { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret
        );
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          toast.error(error.message);
          return;
        }
        toast.success(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      }

      toast.success(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });
  }, []);

  return (
    <div id="payment-request-button" className="PaymentRequestButton"></div>
  );
};

export default PaymentRequestForm;
