import {
  faClose,
  faExclamation,
  faExclamationCircle,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setuserDetailsData } from "../../redux/slices/userReducer";
import axiosInstance from "../../utils/axiosInstance";
import "./packageselection.css";

const Packageselection = ({
  handlepackage,
  isLoading,
  handleChange,
  package_timeframe,
  packages,
  package_id,
  handleselectpackage,
  handleNext,
}: any) => {
  const { userdetails } = useSelector((state: any) => state.user);

  return (
    <React.Fragment>
      <div className="pricingDv">
        <h1 className="text-center h3 mb-0 textHd">Our Pricing</h1>
        <p className="text-center plancolor">
          Your current plan is {userdetails.package.package_name}{" "}
        </p>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <ul className="nav nav-tabs justify-content-center pricing-nav-one">
              <li
                className="nav-item clickable"
                onClick={(e) => {
                  handleChange("YEARLY");
                }}
              >
                <a
                  className={`nav-link ${
                    package_timeframe === "MONTHLY" ? "" : "active"
                  }`}
                  data-toggle="tab"
                >
                  Annually
                </a>
              </li>
              <li
                className="nav-item clickable"
                onClick={(e) => {
                  handleChange("MONTHLY");
                }}
              >
                <a
                  className={`nav-link ${
                    package_timeframe === "MONTHLY" ? "active" : ""
                  }`}
                  data-toggle="tab"
                >
                  Monthly
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="" id="month">
                <div className="row justify-content-center">
                  {packages.map((value: any, idx: any) => {
                    if (idx !== 0) {
                      return (
                        <div
                          className={`col-lg-4 col-md-4 ${
                            packages.length - 1 !== idx ? "pr-0" : ""
                          }`}
                          key={idx}
                        >
                          <div
                            className={`pr-table-wrapper card shadow ${
                              package_id === value.id ? "standard" : ""
                            }`}
                          >
                            <div className="pack-name">
                              {value.package_name}{" "}
                            </div>
                            <div className="bill-cycle mb-2">
                              <span>
                                $
                                {package_timeframe.toLowerCase() === "monthly"
                                  ? (
                                      parseInt(value.original_price) / 12
                                    ).toFixed(2)
                                  : value.original_price}
                                /user
                              </span>{" "}
                              Billed {package_timeframe.toLowerCase()}
                            </div>
                            <div className="pack-rec font-rubik">
                              {" "}
                              ${parseInt(value.original_price)} One-time setup
                              fee{" "}
                            </div>
                            <a
                              href="https://ci.cloudsign.pro/e-signature"
                              className="theme-btn-four  clickable"
                            >
                              View Plan
                            </a>
                            <a
                              className="theme-btn-four clickable"
                              onClick={() => {
                                handleselectpackage(value.id);
                              }}
                            >
                              choose Plan
                            </a>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="text-center">
        <button className="btn btn-primary mt-4" onClick={() => handleNext()}>
          Next
        </button>
        <button
          className="btn btn-secondary mt-4 ml-2"
          onClick={() => handlepackage(false)}
        >
          Close
        </button>
      </div>
    </React.Fragment>
  );
};
export default Packageselection;
