import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Rightpanel.css";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faB } from "@fortawesome/free-solid-svg-icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function RightpanelIndex({ fieldState, changeinput, fonts, fontsizes }: any) {
  const parameter = useParams();
  const { state: pageState } = useLocation();
  const loc = useLocation();
  const navigate = useNavigate();
  const [accordiandetails, setAccordiandetails] = useState(true);
  const [accordianuser, setAccordianuser] = useState(true);
  const [loader, setLoader] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const templatedetails = useSelector((state: any) => state.userpdf);
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <div className="desc-container">
      <div className="fieldscss">
        <div className="fieldscss">
          <div className="row">
            <div className="col">
              <h5 className="ml-0">Selected Field</h5>
            </div>
          </div>
          <div className="col-lg-12 rightcontainer">
            <div className="accordion" id="accordionPanelsStayOpenExample">
            {templatedetails.templatedata.only_signer === "0" && (
                <div className="row">
                  <label htmlFor="userrole">
                    <strong className="mb-2">Role:</strong>
                  </label>
                  <select
                    name="userrole"
                    id="userrole"
                    value={fieldState.customData.role_id}
                    onChange={(e) => {
                      changeinput(
                        e,
                        fieldState.name,
                        "role_id",
                        e.target.value.toString(),
                        fieldState.pageIndex
                      );
                    }}
                    className="select-role form-control"
                  >
                    {templatedetails.templatedata.roles.map(
                      (val: any, idx: any) => (
                        <option value={val.id} key={idx}>
                          {val.role}
                        </option>
                      )
                    )}
                  </select>
                </div>
              )}
              {templatedetails.templatedata.only_signer === "0" && (
                <div className="row">
                  <strong className="mb-3">Description: &nbsp;</strong>

                  <textarea
                    className="form-control"
                    style={{ height: "153px" }}
                    value={fieldState.customData.description}
                    onChange={(e) => {
                      changeinput(
                        e,
                        fieldState.name,
                        "description",
                        e.target.value,
                        fieldState.pageIndex
                      );
                    }}
                  ></textarea>
                </div>
              )}
              {templatedetails.templatedata.only_signer === "0" && (
                <div className="row">
                  <strong className="mb-2">Label: &nbsp;</strong>

                  <input
                    className="form-control"
                    onChange={(e) => {
                      changeinput(
                        e,
                        fieldState.name,
                        "label",
                        e.target.value,
                        fieldState.pageIndex
                      );
                    }}
                    value={fieldState.label}
                  />
                </div>
              )}

              <div className="row mt-4">
                <label className="check-container check-container-font">
                  <input
                    type="checkbox"
                    checked={fieldState.isBold}
                    onChange={(e) => {
           
                      changeinput(
                        e,
                        fieldState.name,
                        "isBold",
                        e.target.checked,
                        fieldState.pageIndex
                      );
                    }}
                    className="mr-2"
                  />
                  <span className="checkmark"></span>
                  Bold
                </label>
              </div>
              {/* <div className="custom-control">
                <input
                  type="checkbox"
                  // className="custom-control-input"
                  id="customSwitch55"
                  checked={fieldState.isBold}
                  onChange={(e) => {
        
                    changeinput(
                      e,
                      fieldState.name,
                      "isBold",
                      e.target.checked,
                      fieldState.pageIndex
                    );
                  }}
                />
                <label
                  className="check-container check-container-font"
                  htmlFor="customSwitch55"
                >
                  Bold
                </label>
              </div> */}

              <div className="row">
                <label htmlFor="font">
                  <strong className="mb-2">Font:</strong>
                </label>
                <select
                  name="font"
                  id="font"
                  value={fieldState.font}
                  onChange={(e) => {
                    changeinput(
                      e,
                      fieldState.name,
                      "font",
                      e.target.value.toString(),
                      fieldState.pageIndex
                    );
                  }}
                  className="select-role form-control"
                >
                  {fonts.map((val: any, idx: any) => (
                    <option value={val} key={idx}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <label htmlFor="font">
                  <strong className="mb-2">Font size:</strong>
                </label>
                <select
                  name="font"
                  id="font"
                  value={fieldState.fontSize}
                  onChange={(e) => {
                    changeinput(
                      e,
                      fieldState.name,
                      "fontSize",
                      e.target.value.toString(),
                      fieldState.pageIndex
                    );
                  }}
                  className="select-role form-control"
                >
                  {fontsizes.map((val: any, idx: any) => (
                    <option value={val} key={idx}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>

             
              {templatedetails.templatedata.only_signer === "0" && (
                <div className="row mt-4">
                  <label className="check-container check-container-font">
                    <input
                      type="checkbox"
                      checked={fieldState.required}
                      onChange={(e) => {
                        changeinput(
                          e,
                          fieldState.name,
                          "required",
                          e.target.checked,
                          fieldState.pageIndex
                        );
                      }}
                      className="mr-2"
                    />
                    <span className="checkmark"></span>
                    Required
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RightpanelIndex);
