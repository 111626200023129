import { faExclamation, faExclamationCircle, faQuestionCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import "./logout.css";

const LogoutConfirmation = (props: any) => {

    return (
        <>
            <Modal show={props.show}
                centered>

                {/* <Modal.Header >
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header> */}

                <Modal.Body>

                    <div className='logout-body'>
                        <table className='model-content'>
                            <tbody>
                                <tr>
                                    <td><FontAwesomeIcon icon={faQuestionCircle} className="logout-icon" /></td>
                                    <td>
                                        <div className='logout-message'>{props.body}</div>
                                        {/* <div>You want to logout?</div> */}
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </div>
                </Modal.Body>

                <Modal.Footer className='border-0'>
                    <button className="btn btn-sm btn-logout-cancel" onClick={() => props.onClick({ msg: false })} >Cancel</button>
                    <button type="submit" className="btn btn-sm btn-logout" onClick={() => props.onClick({ msg: true })}>Logout</button>
                </Modal.Footer>

            </Modal>
        </>
    )
}
export default LogoutConfirmation;