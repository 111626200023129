import { createSlice } from "@reduxjs/toolkit";
// import { videodetailtype } from "global/types";
import { dispatch } from "../store";
// import axiosInstance from "utils/axios";

export type CommonStateType = {
  documents: File | null;
  template_name: string;
};


const initialState: CommonStateType = {
  documents: null,
  template_name: ""
};

const slice = createSlice({
  name: "dndpdf",
  initialState,
  reducers: {
    setdndpdf(state, action) {
      state.documents = action.payload.documents;
      state.template_name = action.payload.template_name;
    },
    cleardndpdf(state) {
      state.documents = null;
      state.template_name = "";
    },
  },
});

export function setdndpdfData(pdfdata: any) {
  dispatch(slice.actions.setdndpdf(pdfdata));
}
export function cleardndpdfData() {
  dispatch(slice.actions.cleardndpdf());
}

export default slice.reducer;
