import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import "./assets/css/style.css";
import Router from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-modal/styles.css";
import axiosInstance from "./utils/axiosInstance";
import { setuserDetailsData } from "./redux/slices/userReducer";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

function App() {

  const location = useLocation();
  const { userdetails } = useSelector((state: any) => state.user);
  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/verify-otp" ||
      location.pathname === "/otp" ||
      location.pathname === "/usersuccess" ||
      // location.pathname === "/signup" ||
      location.pathname.includes("/templateredirect")
    ) {
      document.body.classList.add("bg-log");
    } else {
      document.body.classList.remove("bg-log");
    }
    // if (userdetails === null) {
    //   fetchData();
    // }
  }, [location.pathname, userdetails]);

  
  return (
    <>
      
      {/* <Elements stripe={loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh")}> */}
        <Router />
        <ToastContainer />
        
      {/* </Elements> */}
    </>
  );
}

export default App;
