import React, { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
/**
 * Your Component
 */
function SquareBoxDraggableDate({ handleDragStart, handleTextClick }: any) {
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "input-box",
  //   item: {
  //     type: "input",
  //     position: "new",
  //     labelName: "title",
  //   },
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging(),
  //   }),
  // }));
  return (
    <div
      className="form-check box-height"
      onDragStart={(e) => handleDragStart(e, "input", "Date")}
      onClick={() => handleTextClick()}
      draggable="true"
    >
      {/* <div ref={drag}> */}
      <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
      <label className="form-check-label" htmlFor="exampleCheck1">
        Date Signed
      </label>
      {/* </div> */}
    </div>
  );
}

export default React.memo(SquareBoxDraggableDate);
