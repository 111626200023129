import React, { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
/**
 * Your Component
 */
function CheckBoxDraggable({ handleDragStart, handleTextClick }: any) {
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "input-box",
  //   item: {
  //     type: "input",
  //     position: "new",
  //     labelName: "input",
  //   },
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging(),
  //   }),
  // }));

  return (
    <div
      className="form-check box-height"
      onDragStart={(e) => handleDragStart(e, "check", "checkbox")}
      onClick={handleTextClick}
      draggable="true"
    >
      {/* <div ref={drag}> */}
      <FontAwesomeIcon icon={faSquareCheck} className="mr-2" />
      <label className="form-check-label" htmlFor="exampleCheck1">
        Checkbox
      </label>
      {/* </div> */}
    </div>
  );
}

export default React.memo(CheckBoxDraggable);
