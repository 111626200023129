import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices

import userReducer from "./slices/userReducer";
import pdfReducer from "./slices/pdfReducer";
import elementReducer from "./slices/elementReducer";
import contractReducer from "./slices/contractReducer";
import dndpdfReducer from "./slices/dndpdfReducer"


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  userpdf: pdfReducer,
  elementpdf: elementReducer,
  contractpdf: contractReducer,
  dndpdf: dndpdfReducer,
});

export { rootPersistConfig, rootReducer };
