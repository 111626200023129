import React, { useState, useEffect } from "react";
// import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/free-solid-svg-icons";
/**
 * Your Component
 */
function InitialSignatureBoxDraggable({ handleDragStart, handleTextClick }: any) {
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "image-box",
  //   item: {
  //     type: "image",
  //     position: "new",
  //     labelName: "image",
  //   },
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging(),
  //   }),
  // }));

  return (
    <div
      className="form-check box-height"
      onDragStart={(e) => handleDragStart(e, "signature", "initial_signature")}
      onClick={handleTextClick}
      draggable="true"
    >
      {/* <div ref={drag}> */}
      <FontAwesomeIcon icon={faPenNib} className="mr-2" />
      <label className="form-check-label" htmlFor="exampleCheck1">
        Initial Signature
      </label>
      {/* </div> */}
    </div>
  );
}

export default React.memo(InitialSignatureBoxDraggable);
