import React, { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
/**
 * Your Component
 */
function SquareBoxDraggableName({ handleDragStart, handleTextClick }: any) {

  return (
    <div
      className="form-check box-height"
      onDragStart={(e) => handleDragStart(e, "input", "full name")}
      onClick={handleTextClick}
      draggable="true"
    >
      {/* <div ref={drag}> */}
      <FontAwesomeIcon className="mr-2" icon={faUser} />
      <label className="form-check-label" htmlFor="exampleCheck1">
        Name
      </label>
      {/* </div> */}
    </div>
  );
}

export default React.memo(SquareBoxDraggableName);
