import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const CustomDateRange = (props: any) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data: any) => {
        props.onHide({ msg: 'CREATED', data: { data } })
    }
    return (
        <>
            <Modal size="sm" show={props.show} onHide={() => props.onHide({ msg: '' })}>
                <Modal.Header >
                    <h5>{props.title}</h5>
                    <div className="dropdown no-arrow">
                        <span className="icon-close-model"
                            onClick={props.onHide}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </span>
                    </div>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >Start Date</label>
                                <input type="date" className="form-control"
                                    {...register("start_date", { required: true })} />

                                {errors.start_date && <small className="text-danger">Select your start date</small>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >End Date</label>
                                <input type="date" className="form-control"
                                    {...register("end_date", { required: true })}
                                />
                                {errors.end_date ? (
                                    <>
                                        {errors.end_date.type === "required" && (
                                            <small className="text-danger">
                                                Enter your auth token
                                            </small>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-sm " onClick={() => props.onClick({ msg: 'Modal Closed!' })} >Close</Button>
                        <Button type="submit" className="btn btn-sm">Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default CustomDateRange;