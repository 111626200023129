import {
  faClose,
  faExclamation,
  faExclamationCircle,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setuserDetailsData } from "../../redux/slices/userReducer";
import axiosInstance from "../../utils/axiosInstance";
import "./packageselection.css";

import {
  Stripe,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentElement,
  Elements,
  CardElement,
} from "@stripe/react-stripe-js";
import useResponsiveFontSize from "./useResponsiveFontSize";
import PaymentRequestForm from "./paymentrequestform";

import { loadStripe } from "@stripe/stripe-js";

// const {publishableKey}= await fetch('/config').then(r=>r.json())
// const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Paymentcheckout = ({ handlepackage, submitpackage }) => {
  const fetchstripe = async () => {
    const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
    const stripenew = await stripePromise;
    const paymentRequest = stripenew.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Demo total",
        amount: 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    const elements = stripenew.elements();
    const prButton = elements.create("paymentRequestButton", {
      paymentRequest,
    });
    const result = await paymentRequest.canMakePayment();
    console.log(stripenew, paymentRequest,elements,prButton,result, "check");
    if (result) {
      prButton.mount("#payment-request-button");
      paymentRequest.on("paymentmethod", async (ev) => {
        console.log(ev);
        const settings = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjVkZjFmOTQ1ZmY5MDZhZWFlZmE5M2MyNzY5OGRiNDA2ZDYwNmIwZTgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2Nzg5NjIyNzAsImF1ZCI6IjY1MTY0OTg2ODcyLXFlaGtubWh2ZTJmM3EwNGdzcDl0ZHR0M24yZmRqajVjLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAwNTUyNTU3ODI3MTc4OTAzNjM0IiwiZW1haWwiOiJjbG91ZHNpZ25wcm9AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6IjY1MTY0OTg2ODcyLXFlaGtubWh2ZTJmM3EwNGdzcDl0ZHR0M24yZmRqajVjLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwibmFtZSI6IlV0YWh0ZWNobGFiIGNsb3Vkc2lnbnBybyIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BR05teXhiSmxxSUJ3MFJLTHZnc1lxc21OblNHdnhlbU1CdzN0Mkcyc1QxQj1zOTYtYyIsImdpdmVuX25hbWUiOiJVdGFodGVjaGxhYiIsImZhbWlseV9uYW1lIjoiY2xvdWRzaWducHJvIiwiaWF0IjoxNjc4OTYyNTcwLCJleHAiOjE2Nzg5NjYxNzAsImp0aSI6IjM4ZWU2MDgzMDdkNjQ1ODlhYTQ3OGU5NTkxMjViZGMzMWYxMTAzYWMifQ.13HGgM_i60n3J0oVJsOKWCKmHmMmB1OApNjQMg0M_UYzWR08YdRQw3o4tyU9lpmnFFW-iHjB5SWKJjW7LDEtbCXY0He4cvcvuGYXtR_VqPrfUAqMyMkLcMar-xwSCdogjWwlNCC0E5e_tin0mnKFAV0zGuXpM-iFcPa_6l1RnlObjQWqz0NHwkF9dtcKvYvO_npF5PjlSoXM8z6IUKiJ8oUeWTtYYQZcKjKS6NCG96xuk5NceFVCkwvAGLI8pyFLf3nL-FvuMbYnmeSKOglXBcgKwhFyR19YvlK-0D7tckRkxyHvPojfgoJGpdikTwAPKcroD0Flp_eGtuH2Likzxw",
          },
          body: JSON.stringify({
            amount: "1",
          }),
        };

        stripepaymentfnc(stripenew,settings,ev);
      });
    } else {
      document.getElementById("payment-request-button").style.display =
        "none";
    }
  };
  const stripepaymentfnc=async(stripenew,settings,ev)=>{
    const response = await fetch(
      "http://127.0.0.1:5000/api/v1/create-payment-intent",
      settings
    );

    const res = await response.json();
    // Render the form using the clientSecret
    const clientSecret = res.data.intent.client_secret;

    const finger_settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjVkZjFmOTQ1ZmY5MDZhZWFlZmE5M2MyNzY5OGRiNDA2ZDYwNmIwZTgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2Nzg5NjIyNzAsImF1ZCI6IjY1MTY0OTg2ODcyLXFlaGtubWh2ZTJmM3EwNGdzcDl0ZHR0M24yZmRqajVjLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAwNTUyNTU3ODI3MTc4OTAzNjM0IiwiZW1haWwiOiJjbG91ZHNpZ25wcm9AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6IjY1MTY0OTg2ODcyLXFlaGtubWh2ZTJmM3EwNGdzcDl0ZHR0M24yZmRqajVjLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwibmFtZSI6IlV0YWh0ZWNobGFiIGNsb3Vkc2lnbnBybyIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BR05teXhiSmxxSUJ3MFJLTHZnc1lxc21OblNHdnhlbU1CdzN0Mkcyc1QxQj1zOTYtYyIsImdpdmVuX25hbWUiOiJVdGFodGVjaGxhYiIsImZhbWlseV9uYW1lIjoiY2xvdWRzaWducHJvIiwiaWF0IjoxNjc4OTYyNTcwLCJleHAiOjE2Nzg5NjYxNzAsImp0aSI6IjM4ZWU2MDgzMDdkNjQ1ODlhYTQ3OGU5NTkxMjViZGMzMWYxMTAzYWMifQ.13HGgM_i60n3J0oVJsOKWCKmHmMmB1OApNjQMg0M_UYzWR08YdRQw3o4tyU9lpmnFFW-iHjB5SWKJjW7LDEtbCXY0He4cvcvuGYXtR_VqPrfUAqMyMkLcMar-xwSCdogjWwlNCC0E5e_tin0mnKFAV0zGuXpM-iFcPa_6l1RnlObjQWqz0NHwkF9dtcKvYvO_npF5PjlSoXM8z6IUKiJ8oUeWTtYYQZcKjKS6NCG96xuk5NceFVCkwvAGLI8pyFLf3nL-FvuMbYnmeSKOglXBcgKwhFyR19YvlK-0D7tckRkxyHvPojfgoJGpdikTwAPKcroD0Flp_eGtuH2Likzxw",
      },
    };

    let data = { paymentMethodId: ev.paymentMethod.id };
    finger_settings.body = JSON.stringify(data);
    console.log(finger_settings);

    const response_two = await fetch(
      "http://127.0.0.1:5000/api/v1/get-fingerprint",
      finger_settings
    ).then((r) => r.json());

    console.log(response_two);

    //console.log(clientSecret);

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripenew.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
    console.log(paymentIntent);

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripenew.confirmCardPayment(
          clientSecret
        );
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
        }
      } else {
        // The payment has succeeded.
      }
    }
  }
  useEffect(() => {
    fetchstripe();
    fetchclientexist();
  }, []);

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const [isclientVerified, setIsclientVerified] = useState(false);
  const { userdetails } = useSelector((state) => state.user);
  // const elements = useElements();

  const options = useMemo(() => {
    // const clientSecret = userdetails.client_api_key;
    const style = {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    return {
      // clientSecret,
      style,
    };
  }, []);

  const fetchclientexist = async () => {
    try {
      const url = `check-stripe-customer`;
      const response = await axiosInstance.get(url);

      if (response.status == 200) {
        setIsclientVerified(true);
      }
      return response;
    } catch (err) {
      if (err.response.status === 400) {
        createstripeclient();
      }
    }
  };
  const createstripeclient = async () => {
    try {
      const url = `create-stripe-customer`;
      const response = await axiosInstance.post(url);
      if (response.status == 200) {
        setIsclientVerified(true);
      }
      return response;
    } catch (err) {}
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    // setProcessing(true);
    // if (!stripe || !elements) {
    //   // Stripe.js has not yet loaded.
    //   // Make sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }

    // if (elements.getElement(CardElement) !== null) {
    //   // const nextpayload = await fetch("https://api.stripe.com/v1/tokens", {
    //   //   method: "POST",
    //   //   headers: {
    //   //     "Content-Type": "application/x-www-form-urlencoded",
    //   //     Authorization:
    //   //       "Basic " +
    //   //       btoa("sk_test_51MZISzLYWMdXUrpEIJkDj9U0znieIqK8H8OEzFD6OkOJz3F58YVOwMALroTSJEp5Rgu88a6sE96PoomV1DcZTuz000aYEg0Xlq:"),
    //   //   },
    //   //   body: elements.getElement(CardElement),
    //   // });
    //   const payload = await stripe.createToken(
    //     elements.getElement(CardElement)
    //   );

    //   if (payload.error) {
    //     setError(`Payment failed ${payload.error.message}`);
    //     setProcessing(false);
    //   } else {
    //     addcard(payload);
    //     // const reversepayload = await stripe.retrieveIssuingCard(
    //     //   payload.token.card.id
    //     // );

    //     setError(null);
    //     setProcessing(false);
    //     setSucceeded(true);
    //   }
    // }
  };
  const addcard = async (cardtoken) => {
    try {
      const url = `add-customer-card`;
      const response = await axiosInstance.post(url, { ...cardtoken });
      if (response.status === 200) {
        toast.success(response.data.msg);
      }
      return response;
    } catch (err) {}
  };
  const retrieveaddcard = async (cardtoken) => {
    try {
      const url = `add-customer-card`;
      const response = await axiosInstance.post(url, { ...cardtoken });
      if (response.status === 200) {
      }
      return response;
    } catch (err) {}
  };
  return (
    <div className="pricingDv element-css">
      <h1 className="text-center h3 mb-0 textHd">Checkout</h1>
      <p className="text-center plancolor">Pay using card</p>
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={options}
          onChange={handleChange}
        />
        <div id="payment-request-button">
          {/* <!-- A Stripe Element will be inserted here. --> */}
        </div>
        {/* <PaymentElement
          options={{ clientSecret: userdetails.client_api_key }}
        /> */}

        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}

        <div className="text-center">
          <button
            className="btn-upgrade-plan mt-4"
            onClick={handleSubmit}
            // disabled={processing || disabled || succeeded}
            id="submit"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
          <button
            className="btn-close-plan mt-4 ml-3"
            onClick={() => handlepackage(false)}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};
export default Paymentcheckout;
