import { createSlice } from "@reduxjs/toolkit";
// import { videodetailtype } from "global/types";
import { dispatch } from "../store";
// import axiosInstance from "utils/axios";

export type CommonStateType = {
  template_id: number | string;
  subject: string;
  message: string;
  is_mobile_send:number;
  role_description: roledatatype[] | [];
};
type roledatatype = {
  rolename: string;
  roleid: number;
  name: string;
  email: string;
};

const initialState: CommonStateType = {
  template_id: "",
  subject: "",
  message: "",
  is_mobile_send:0,
  role_description: [],
};

const slice = createSlice({
  name: "contractpdf",
  initialState,
  reducers: {
    setcontract(state, action) {
      state.template_id = action.payload.template_id;
      state.subject = action.payload.subject;
      state.message = action.payload.message;
      state.is_mobile_send=action.payload.is_mobile_send;
      state.role_description = [...action.payload.roledescription];
    },
    clearcontract(state) {
      state.template_id = "";
      state.subject = "";
      state.message = "";
      state.is_mobile_send=0;
      state.role_description = [];
    },
  },
});

export function setcontractData(contractdata: any) {
  dispatch(slice.actions.setcontract(contractdata));
}
export function clearcontractData() {
  dispatch(slice.actions.clearcontract());
}

export default slice.reducer;
