import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
// import axios from "../utils/axios";
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
} from "../global/AuthenticationTypes";
import { removeSession, setSession } from "../utils/token";
import axiosInstance from "../utils/axiosInstance";
import { dataSetType, userDetType } from "../global/types";
import { useSelector } from "react-redux";
import { setuserDetailsData } from "../redux/slices/userReducer";
// ----------------------------------------------------------------------

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
};

type CreateSessionType = {
  dataSet: any;
  userDet: any;
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const getRoleName = (roleName: string) => {
  switch (roleName?.toLowerCase()) {
    case "madmin":
      return "Merchant Admin";

    case "musers":
      return "User";

    default:
      return "";
  }
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { userdetails } = useSelector((state: any) => state.user);
  const access_token = window.localStorage.getItem("access_token");
  useEffect(() => {
    const initialize = async () => {
      try {
        const tokens = window.localStorage.getItem("access_token");
        const user = window.localStorage.getItem("userData");
        if (tokens) {
          setSession(tokens);
          // if (userdetails === null) {
          //   fetchData();
          // }
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: null,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    // CALL this API to fetch account details on login for roles, elements etc
    initialize();
  }, []);

  useEffect(() => {
    if (access_token && userdetails === null) {
      fetchData();
    }
  }, [access_token]);

  const fetchData = async () => {
    try {
      const url: string = `user-details`;
      const response = await axiosInstance.get(url);

      if (response.status == 200) {
        setuserDetailsData(response.data.data.user_json);
        sessionStorage.setItem("fname", response.data.data.user_json.fname);

        localStorage.setItem(
          "company_bucket",
          response.data.data.user_json.company.company_bucket
        );
      } else {
        sessionStorage.setItem("file_url", "");
      }
      return response;
    } catch (err: any) {}
  };

  const createSession = ({ dataSet, userDet }: CreateSessionType) => {
    setSession(dataSet);
    updateUserContext(userDet);
  };

  const updateUserContext = async (userDet: userDetType) => {
    dispatch({
      type: Types.Login,
      payload: {
        user: userDet,
      },
    });
  };

  const login = async (dataSet: any, userDet: any) => {
    createSession({
      dataSet,
      userDet,
    });
  };

  const logout = async () => {
    removeSession();
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        updateUserContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
