import { createSlice } from "@reduxjs/toolkit";
// import { videodetailtype } from "global/types";
import { dispatch } from "../store";
// import axiosInstance from "utils/axios";

export type CommonStateType = {
  userdetails: {
    added_by: null | string;
    address: string;
    city: string;
    client_api_key:string;
    company: any;
    company_id: number;
    country: string;
    created_at: string;
    deleted_by: null;
    email: string;
    email_verified: string;
    file_url: string;
    fname: string;
    full_signature: string;
    full_signature_file_url: string;
    id: number;
    initial_signatre_file_url: string;
    initial_signature: string;
    is_active: string;
    is_deleted: string;
    is_sub_user: string;
    lat: null;
    lname: string;
    lon: null;
    permissions: any[];
    pic: string;
    state: string;
    status: null;
    updated_at: string;
    user_groups: any[];
    username: string;
    zip: string;
  } | null;
};

const initialState: CommonStateType = { userdetails:null };

const slice = createSlice({
  name: "userdetails",
  initialState,
  reducers: {
    setuserDetails(state, action) {
      state.userdetails = action.payload;
    },
    clearuserDetails(state) {
      state.userdetails = null;
    },
  },
});

export function setuserDetailsData(userdata: any) {
  dispatch(slice.actions.setuserDetails({ ...userdata }));
}
export function clearsetuserDetailsData() {
  dispatch(slice.actions.clearuserDetails());
}

export default slice.reducer;
